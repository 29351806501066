<div class="flex flex-col min-h-screen items-center">
  <header class="w-full h-24 bg-basic_light grid grid-cols-1 items-center justify-center">
    <div class="flex flex-row items-center justify-center">
      <h1 class="font-bold text-base font-sans uppercase text-center">Impact Poker</h1>
      
        <img class="object-contain w-36" src="/assets/img/logo_agile_engage.png" alt="">
    </div>

  </header>


  <main class="flex flex-col items-center max-w-screen-lg w-full py-4 text-text ">
    <div>
      <h2 class="text-md sm:text-2xl text-basic bold" >Bienvenue sur la plateforme Impact poker !</h2>
    </div>
    <div class="my-2  sm:px-24 flex" >
      <p (click)="collapseText()"  id="descriptionText" class="tracking-wider truncate max-w-xs sm:max-w-2xl sm:whitespace-normal self-start  sm:self-center sm:overflow-visible overflow-ellipsis text-center text-sm font-light">
        Nous sommes heureux de t'accueillir. L'Impact poker te permet
        d'évaluer l'impact de tes US. Pour chaque US, un vote sera lancé et tous les membres de l'équipe pourront voter
        ! Lorsqu'une US est jugée défavorable, l'objectif est de trouver une solution pour la rentre neutre, ou même
        favorable. Renseigne quel impact l'équipe va évaluer : environnemental, sociétal ou les deux ? (cela dépend du
        mandat que l'équipe a choisi)
      </p>
      <div class="sm:hidden">
        <p (click)="collapseText()" *ngIf="!displayText" id="collapser" class="text-sm underline underline-offset-1 italic">plus</p>
        <p (click)="collapseText()" *ngIf="displayText" id="collapser" class="text-xl">-</p>
      </div>
    </div>

    <div class="max-w-screen-lg">
      <div>
        <h3 class="text-center  font-bold text-base md:pt-5">Créer une salle</h3>
      </div>

      <div>
        <form class="flex flex-col w-full pt-4 gap-4">
          <label class="sr-only" for="usernameCreate">Votre nom d'utilisateur lors de la création de la salle</label>
          <div id="name">
            <label class=" font-medium">Votre nom :</label>
            <input class="h-12 w-full border border-solid border-neutral-200 rounded bg-white-400 pl-2" type="text"
              id="usernameCreate" name="usernameCreate" maxlength="20" placeholder="Nom" [(ngModel)]="usernameCreate">
          </div>

          <div id="roomType" class="grid grid-row-2">
            <div class="col-span-6">
              <p class="w-full py-2 md:py-3">Je souhaite évaluer l'impact : </p>
            </div>

            <div class="flex flex-row align-middle gap-1">
              <div class="w-5 h-5 grid place-content-center border-2 border-basic rounded">
                <input type="checkbox" id="environnemental" [checked]="roomVoteType.environnemental"
                  class="w-4 h-4 peer appearance-none absolute" tabindex="0"
                  (change)="roomVoteType.environnemental = !roomVoteType.environnemental"
                  >
                <svg class="invisible peer-checked:visible w-3 h-3 fill-basic" viewBox="0 0 18 18"
                  xmlns="http://www.w3.org/2000/svg" aria-labelledby="checkmark pour cocher le vote environnemental">
                  <path
                    d="M16.5 0H1.5C1.10218 0 0.720644 0.158035 0.43934 0.43934C0.158035 0.720644 0 1.10218 0 1.5V16.5C0 16.8978 0.158035 17.2794 0.43934 17.5607C0.720644 17.842 1.10218 18 1.5 18H16.5C16.8978 18 17.2794 17.842 17.5607 17.5607C17.842 17.2794 18 16.8978 18 16.5V1.5C18 1.10218 17.842 0.720644 17.5607 0.43934C17.2794 0.158035 16.8978 0 16.5 0ZM13.2806 7.28063L8.03063 12.5306C7.96097 12.6004 7.87825 12.6557 7.78721 12.6934C7.69616 12.7312 7.59856 12.7506 7.5 12.7506C7.40144 12.7506 7.30384 12.7312 7.21279 12.6934C7.12175 12.6557 7.03903 12.6004 6.96937 12.5306L4.71937 10.2806C4.57864 10.1399 4.49958 9.94902 4.49958 9.75C4.49958 9.55098 4.57864 9.36011 4.71937 9.21937C4.86011 9.07864 5.05098 8.99958 5.25 8.99958C5.44902 8.99958 5.63989 9.07864 5.78063 9.21937L7.5 10.9397L12.2194 6.21937C12.2891 6.14969 12.3718 6.09442 12.4628 6.0567C12.5539 6.01899 12.6515 5.99958 12.75 5.99958C12.8485 5.99958 12.9461 6.01899 13.0372 6.0567C13.1282 6.09442 13.2109 6.14969 13.2806 6.21937C13.3503 6.28906 13.4056 6.37178 13.4433 6.46283C13.481 6.55387 13.5004 6.65145 13.5004 6.75C13.5004 6.84855 13.481 6.94613 13.4433 7.03717C13.4056 7.12822 13.3503 7.21094 13.2806 7.28063Z" />
                </svg>
              </div>
              <div class="flex flex-row gap-1">

                <svg class="w-5" xmlns="http://www.w3.org/2000/svg" width="24" viewBox="0 0 24 24" fill="none">
                  <path
                    d="M19.2572 14.1629C18.3581 14.7094 17.3254 14.9969 16.2732 14.9935C15.3916 14.9865 14.5201 14.8062 13.7082 14.4629C13.0824 15.3463 12.7475 16.4028 12.75 17.4854V20.2501C12.7503 20.3529 12.7293 20.4546 12.6886 20.549C12.6478 20.6434 12.5881 20.7284 12.5131 20.7988C12.4381 20.8691 12.3495 20.9233 12.2526 20.9579C12.1558 20.9925 12.0529 21.0069 11.9503 21.0001C11.7576 20.9833 11.5783 20.8942 11.4484 20.7508C11.3185 20.6074 11.2476 20.4201 11.25 20.2266V19.0604L7.62941 15.4397C7.09119 15.6405 6.52197 15.7456 5.94753 15.7501C5.15673 15.752 4.38073 15.5356 3.70503 15.1247C1.66222 13.8835 0.562535 11.0269 0.775347 7.48037C0.786062 7.29689 0.863779 7.12373 0.993742 6.99376C1.1237 6.8638 1.29686 6.78608 1.48035 6.77537C5.02691 6.56631 7.88347 7.66224 9.12097 9.70506C9.60717 10.5058 9.81943 11.443 9.72566 12.3751C9.71983 12.4473 9.69321 12.5163 9.64901 12.5737C9.60481 12.6311 9.54493 12.6744 9.4766 12.6985C9.40828 12.7226 9.33444 12.7264 9.264 12.7094C9.19357 12.6925 9.12956 12.6554 9.07972 12.6029L7.27972 10.7185C7.13793 10.5838 6.94911 10.5098 6.75354 10.5123C6.55797 10.5148 6.37111 10.5936 6.23281 10.7319C6.09451 10.8702 6.01571 11.0571 6.0132 11.2526C6.0107 11.4482 6.08469 11.637 6.21941 11.7788L11.2707 16.9585C11.2763 16.8854 11.2828 16.8122 11.2903 16.7401C11.4544 15.3495 12.068 14.0506 13.0378 13.0407L17.7807 8.02881C17.9214 7.8882 18.0005 7.69745 18.0006 7.49851C18.0007 7.29958 17.9217 7.10876 17.7811 6.96803C17.6405 6.8273 17.4498 6.74819 17.2508 6.7481C17.0519 6.74801 16.8611 6.82695 16.7203 6.96756L12.1266 11.8257C12.0806 11.8744 12.0224 11.9099 11.9581 11.9285C11.8937 11.9471 11.8256 11.9481 11.7607 11.9315C11.6958 11.9148 11.6366 11.8811 11.5892 11.8338C11.5417 11.7865 11.5078 11.7274 11.491 11.6626C11.0466 10.0238 11.2425 8.39256 12.091 6.99193C13.7653 4.22818 17.6616 2.74881 22.5141 3.03381C22.6976 3.04452 22.8707 3.12224 23.0007 3.2522C23.1307 3.38216 23.2084 3.55533 23.2191 3.73881C23.5003 8.59225 22.021 12.4885 19.2572 14.1629Z"
                    fill="#F6A525" />
                </svg>

                <label for="envCheckbox" class="font-bold text-sm">
                  Environnemental
                </label>
              </div>
            </div>
            <div class="flex flex-row align-right gap-1 ml-4">
              <div class="w-5 h-5 grid place-content-center border-2 border-basic rounded">
                <input type="checkbox" [checked]="roomVoteType.societal" id="societal"
                  class="w-4 h-4 peer appearance-none absolute" tabindex="0"
                  (change)="roomVoteType.societal = !roomVoteType.societal"
                  >
                <svg class="invisible peer-checked:visible w-3 h-3 fill-basic" viewBox="0 0 18 18"
                  xmlns="http://www.w3.org/2000/svg" aria-labelledby="checkmark pour cocher le vote sociétal">
                  <path
                    d="M16.5 0H1.5C1.10218 0 0.720644 0.158035 0.43934 0.43934C0.158035 0.720644 0 1.10218 0 1.5V16.5C0 16.8978 0.158035 17.2794 0.43934 17.5607C0.720644 17.842 1.10218 18 1.5 18H16.5C16.8978 18 17.2794 17.842 17.5607 17.5607C17.842 17.2794 18 16.8978 18 16.5V1.5C18 1.10218 17.842 0.720644 17.5607 0.43934C17.2794 0.158035 16.8978 0 16.5 0ZM13.2806 7.28063L8.03063 12.5306C7.96097 12.6004 7.87825 12.6557 7.78721 12.6934C7.69616 12.7312 7.59856 12.7506 7.5 12.7506C7.40144 12.7506 7.30384 12.7312 7.21279 12.6934C7.12175 12.6557 7.03903 12.6004 6.96937 12.5306L4.71937 10.2806C4.57864 10.1399 4.49958 9.94902 4.49958 9.75C4.49958 9.55098 4.57864 9.36011 4.71937 9.21937C4.86011 9.07864 5.05098 8.99958 5.25 8.99958C5.44902 8.99958 5.63989 9.07864 5.78063 9.21937L7.5 10.9397L12.2194 6.21937C12.2891 6.14969 12.3718 6.09442 12.4628 6.0567C12.5539 6.01899 12.6515 5.99958 12.75 5.99958C12.8485 5.99958 12.9461 6.01899 13.0372 6.0567C13.1282 6.09442 13.2109 6.14969 13.2806 6.21937C13.3503 6.28906 13.4056 6.37178 13.4433 6.46283C13.481 6.55387 13.5004 6.65145 13.5004 6.75C13.5004 6.84855 13.481 6.94613 13.4433 7.03717C13.4056 7.12822 13.3503 7.21094 13.2806 7.28063Z" />
                </svg>
              </div>
              <div class="flex flex-row align-bottom gap-1">

                <svg class="w-5" xmlns="http://www.w3.org/2000/svg" width="24" viewBox="0 0 24 24" fill="none">
                  <path
                    d="M9.37496 3.375C9.37496 2.85583 9.52891 2.34831 9.81735 1.91663C10.1058 1.48495 10.5158 1.1485 10.9954 0.949817C11.4751 0.751137 12.0029 0.699154 12.5121 0.80044C13.0213 0.901726 13.489 1.15173 13.8561 1.51885C14.2232 1.88596 14.4732 2.35369 14.5745 2.86289C14.6758 3.37209 14.6238 3.89989 14.4251 4.37955C14.2265 4.8592 13.89 5.26917 13.4583 5.55761C13.0266 5.84605 12.5191 6 12 6C11.3038 6 10.6361 5.72344 10.1438 5.23116C9.65152 4.73887 9.37496 4.07119 9.37496 3.375ZM20.1956 13.1981L15.9534 8.38875C15.7071 8.10943 15.4041 7.88572 15.0647 7.73249C14.7252 7.57926 14.3571 7.50001 13.9846 7.5H10.0153C9.64284 7.50001 9.27468 7.57926 8.93524 7.73249C8.5958 7.88572 8.29285 8.10943 8.04652 8.38875L3.80433 13.1981C3.52887 13.48 3.3754 13.8589 3.37709 14.253C3.37878 14.6471 3.5355 15.0247 3.81337 15.3042C4.09124 15.5836 4.46794 15.7425 4.86202 15.7465C5.2561 15.7504 5.63591 15.5992 5.91933 15.3253L8.34371 13.3781L6.49683 20.3981C6.33615 20.7588 6.32419 21.1684 6.46355 21.5378C6.60291 21.9073 6.88234 22.2069 7.24122 22.3717C7.60009 22.5364 8.00946 22.553 8.38049 22.4179C8.75152 22.2827 9.05428 22.0067 9.22308 21.6497L12 16.875L14.7731 21.6563C14.9419 22.0132 15.2446 22.2893 15.6157 22.4244C15.9867 22.5596 16.3961 22.543 16.7549 22.3783C17.1138 22.2135 17.3933 21.9139 17.5326 21.5444C17.672 21.1749 17.66 20.7654 17.4993 20.4047L15.6562 13.3781L18.0843 15.3253C18.3678 15.5992 18.7476 15.7504 19.1416 15.7465C19.5357 15.7425 19.9124 15.5836 20.1903 15.3042C20.4682 15.0247 20.6249 14.6471 20.6266 14.253C20.6283 13.8589 20.4748 13.48 20.1993 13.1981H20.1956Z"
                    fill="#F6A525" />
                </svg>

                <label for="socCheckbox" class="font-bold  text-sm">
                  Sociétal
                </label>
              </div>
            </div>
          </div>
          <button type="submit"
            class="h-14 w-full text-center font-bold text-base bg-basic rounded disabled:bg-disabled"
            [disabled]="!isUserCreateFilled || !isOneOrMoreRoomTypeChoosed" (click)="createRoom()">C'est parti
            !</button>
        </form>
      </div>

      <div>
        <h3 class="text-center font-bold text-base pt-8 md:pt-16">Rejoindre une salle</h3>
      </div>
      <div>
        <form class="flex flex-col w-full pt-4 gap-4">
          <div>
            <label class="sr-only" for="usernameJoin">Votre nom d'utilisateur lorsque vous rejoignez une salle</label>
            <span>Votre nom :</span>
            <input class="h-12 w-full border border-solid border-neutral-200 rounded bg-white-400 pl-4" type="text"
              id="usernameJoin" name="usernameJoin" maxlength="20" placeholder="Votre nom" [(ngModel)]="usernameJoin">

          </div>
          <div>
            <label class="sr-only" for="roomId">Le code de la salle qui permet de la rejoindre</label>
            <span>Nom de la salle : </span>
            <input class="h-12 w-full border border-solid border-neutral-200 rounded bg-white-400 pl-4" type="text"
              id="usernroomId" name="roomId" maxlength="5" placeholder="Nom de la salle" [(ngModel)]="joinRoomId">

          </div>
          <button type="submit"
            class="h-14 w-full text-center font-bold text-base bg-basic rounded disabled:bg-disabled"
            [disabled]="!isRoomIdFilled || !isUserJoinFilled" (click)="joinRoom()">
            C'est parti !
          </button>
        </form>
      </div>
    </div>

    

  </main>

  <footer class="w-full bg-basic_light flex items-center justify-center mt-auto py-5 px-6">
    <p class="text-base font-sans text-neutral-400 flex flex-row align-middle font-light text-sm text-text">
      
      L'agile engagé by &nbsp;
      <a href="https://www.suricats-consulting.com/" class="flex flex-row align-middle underline">
        Suricats Consulting
      </a>
      <!-- <img class="object-contain w-20" src="/assets/img/suricon.png" alt=""> -->
      &nbsp; is licensed under &nbsp;
      <a href="http://creativecommons.org/licenses/by-sa/4.0/?ref=chooser-v1" target="_blank" class="underline flex flex-row"
        rel="license noopener noreferrer" style="display:inline-block;">Attribution-ShareAlike 4.0 International
      </a>
      <img decoding="async" style="height:22px!important;margin-left:3px;vertical-align:text-bottom;"
          src="https://mirrors.creativecommons.org/presskit/icons/cc.svg?ref=chooser-v1" alt="cc"
          title="Bienvenue dans le monde de l&#039;agile engagé !">
        
        <img decoding="async" style="height:22px!important;margin-left:3px;vertical-align:text-bottom;"
          src="https://mirrors.creativecommons.org/presskit/icons/by.svg?ref=chooser-v1" alt="by"
          title="Bienvenue dans le monde de l&#039;agile engagé !">
       
        <img decoding="async" style="height:22px!important;margin-left:3px;vertical-align:text-bottom;"
          src="https://mirrors.creativecommons.org/presskit/icons/sa.svg?ref=chooser-v1" alt="sa"
          title="Bienvenue dans le monde de l&#039;agile engagé !">
    </p>
    
  </footer>
</div>