<!-- Vote positif -->
    <div class="grid place-content-center h-10  relative" *ngIf="isVotePositive">
        <input type="radio" name="socVote" id="positive" tabindex="0" [value]="0"
            class="z-0 h-full w-full peer appearance-none bg-success absolute rounded-lg">
        <div class="flex flex-row gap-1">
            <svg width="14" class="z-50 fill-text peer-checked:fill-white-200 pointer-events-none" viewBox="0 0 18 18"
                xmlns="http://www.w3.org/2000/svg" aria-labelledby="icône vote positif pour sociétal">
                <path
                    d="M9 0.875C7.39303 0.875 5.82214 1.35152 4.486 2.24431C3.14985 3.1371 2.10844 4.40605 1.49348 5.8907C0.87852 7.37535 0.717618 9.00901 1.03112 10.5851C1.34463 12.1612 2.11846 13.6089 3.25476 14.7452C4.39106 15.8815 5.8388 16.6554 7.41489 16.9689C8.99099 17.2824 10.6247 17.1215 12.1093 16.5065C13.594 15.8916 14.8629 14.8502 15.7557 13.514C16.6485 12.1779 17.125 10.607 17.125 9C17.1227 6.84581 16.266 4.78051 14.7427 3.25727C13.2195 1.73403 11.1542 0.877275 9 0.875ZM12.5672 7.56719L8.19219 11.9422C8.13415 12.0003 8.06522 12.0464 7.98934 12.0779C7.91347 12.1093 7.83214 12.1255 7.75 12.1255C7.66787 12.1255 7.58654 12.1093 7.51067 12.0779C7.43479 12.0464 7.36586 12.0003 7.30782 11.9422L5.43282 10.0672C5.31554 9.94991 5.24966 9.79085 5.24966 9.625C5.24966 9.45915 5.31554 9.30009 5.43282 9.18281C5.55009 9.06554 5.70915 8.99965 5.875 8.99965C6.04086 8.99965 6.19992 9.06554 6.31719 9.18281L7.75 10.6164L11.6828 6.68281C11.7409 6.62474 11.8098 6.57868 11.8857 6.54725C11.9616 6.51583 12.0429 6.49965 12.125 6.49965C12.2071 6.49965 12.2884 6.51583 12.3643 6.54725C12.4402 6.57868 12.5091 6.62474 12.5672 6.68281C12.6253 6.74088 12.6713 6.80982 12.7027 6.88569C12.7342 6.96156 12.7504 7.04288 12.7504 7.125C12.7504 7.20712 12.7342 7.28844 12.7027 7.36431C12.6713 7.44018 12.6253 7.50912 12.5672 7.56719Z" />
            </svg>
            <div class="text-sm z-50">
                Favorable
            </div>
        </div>
        <label for="positive" class="sr-only">Vote "positif"</label>
    </div>


<!-- Vote négatif -->
    <div class="grid place-content-center h-10  relative" *ngIf="isVoteNegative">
        <input  type="radio" name="socVote" tabindex="0" id="negative" [value]="1"
            class="z-0 h-full w-full peer appearance-none bg-danger absolute rounded-lg  ">
        <div class="flex flex-row gap-1">

            <svg width="14" viewBox="0 0 18 18" class="z-50 fill-text peer-checked:fill-white-200 pointer-events-none"
                xmlns="http://www.w3.org/2000/svg" aria-labelledby="icône vote négatif pour sociétal">
                <path
                    d="M9 0.875C7.39303 0.875 5.82214 1.35152 4.486 2.24431C3.14985 3.1371 2.10844 4.40605 1.49348 5.8907C0.87852 7.37535 0.717618 9.00901 1.03112 10.5851C1.34463 12.1612 2.11846 13.6089 3.25476 14.7452C4.39106 15.8815 5.8388 16.6554 7.4149 16.9689C8.99099 17.2824 10.6247 17.1215 12.1093 16.5065C13.594 15.8916 14.8629 14.8502 15.7557 13.514C16.6485 12.1779 17.125 10.607 17.125 9C17.1227 6.84581 16.266 4.78051 14.7427 3.25727C13.2195 1.73403 11.1542 0.877275 9 0.875ZM11.9422 11.0578C12.0003 11.1159 12.0463 11.1848 12.0777 11.2607C12.1092 11.3366 12.1254 11.4179 12.1254 11.5C12.1254 11.5821 12.1092 11.6634 12.0777 11.7393C12.0463 11.8152 12.0003 11.8841 11.9422 11.9422C11.8841 12.0003 11.8152 12.0463 11.7393 12.0777C11.6634 12.1092 11.5821 12.1253 11.5 12.1253C11.4179 12.1253 11.3366 12.1092 11.2607 12.0777C11.1848 12.0463 11.1159 12.0003 11.0578 11.9422L9 9.88359L6.94219 11.9422C6.88412 12.0003 6.81518 12.0463 6.73931 12.0777C6.66344 12.1092 6.58213 12.1253 6.5 12.1253C6.41788 12.1253 6.33656 12.1092 6.26069 12.0777C6.18482 12.0463 6.11588 12.0003 6.05782 11.9422C5.99975 11.8841 5.95368 11.8152 5.92226 11.7393C5.89083 11.6634 5.87466 11.5821 5.87466 11.5C5.87466 11.4179 5.89083 11.3366 5.92226 11.2607C5.95368 11.1848 5.99975 11.1159 6.05782 11.0578L8.11641 9L6.05782 6.94219C5.94054 6.82491 5.87466 6.66585 5.87466 6.5C5.87466 6.33415 5.94054 6.17509 6.05782 6.05781C6.17509 5.94054 6.33415 5.87465 6.5 5.87465C6.66586 5.87465 6.82492 5.94054 6.94219 6.05781L9 8.11641L11.0578 6.05781C11.1159 5.99974 11.1848 5.95368 11.2607 5.92225C11.3366 5.89083 11.4179 5.87465 11.5 5.87465C11.5821 5.87465 11.6634 5.89083 11.7393 5.92225C11.8152 5.95368 11.8841 5.99974 11.9422 6.05781C12.0003 6.11588 12.0463 6.18482 12.0777 6.26069C12.1092 6.33656 12.1254 6.41788 12.1254 6.5C12.1254 6.58212 12.1092 6.66344 12.0777 6.73931C12.0463 6.81518 12.0003 6.88412 11.9422 6.94219L9.8836 9L11.9422 11.0578Z" />
            </svg>
            <div class="text-sm z-50">
                Défavorable
            </div>
        </div>
    </div>

    <!-- Vote Idk -->

    <div class="grid place-content-center h-10  relative" *ngIf="isVoteIdk">
        <input  type="radio" name="socVote" tabindex="0" id="idk" [value]="2"
            class="z-0 h-full w-full peer appearance-none bg-warning absolute rounded-lg ">
        <div class="flex flex-row gap-1">

            <svg width="14" viewBox="0 0 18 18" class="z-50 fill-text peer-checked:fill-white-200 pointer-events-none"
                xmlns="http://www.w3.org/2000/svg" aria-labelledby="icône vote 'je ne sais pas' pour sociétal">
                <path
                    d="M9 0.875C7.39303 0.875 5.82214 1.35152 4.486 2.24431C3.14985 3.1371 2.10844 4.40605 1.49348 5.8907C0.87852 7.37535 0.717618 9.00901 1.03112 10.5851C1.34463 12.1612 2.11846 13.6089 3.25476 14.7452C4.39106 15.8815 5.8388 16.6554 7.4149 16.9689C8.99099 17.2824 10.6247 17.1215 12.1093 16.5065C13.594 15.8916 14.8629 14.8502 15.7557 13.514C16.6485 12.1779 17.125 10.607 17.125 9C17.1227 6.84581 16.266 4.78051 14.7427 3.25727C13.2195 1.73403 11.1542 0.877275 9 0.875ZM9 14C8.81458 14 8.63333 13.945 8.47916 13.842C8.32499 13.739 8.20482 13.5926 8.13387 13.4213C8.06291 13.25 8.04434 13.0615 8.08052 12.8796C8.11669 12.6977 8.20598 12.5307 8.33709 12.3996C8.4682 12.2685 8.63525 12.1792 8.81711 12.143C8.99896 12.1068 9.18746 12.1254 9.35877 12.1964C9.53007 12.2673 9.67649 12.3875 9.77951 12.5417C9.88252 12.6958 9.9375 12.8771 9.9375 13.0625C9.9375 13.3111 9.83873 13.5496 9.66292 13.7254C9.4871 13.9012 9.24864 14 9 14ZM9.625 10.1938V10.25C9.625 10.4158 9.55916 10.5747 9.44195 10.6919C9.32474 10.8092 9.16576 10.875 9 10.875C8.83424 10.875 8.67527 10.8092 8.55806 10.6919C8.44085 10.5747 8.375 10.4158 8.375 10.25V9.625C8.375 9.45924 8.44085 9.30027 8.55806 9.18306C8.67527 9.06585 8.83424 9 9 9C10.0336 9 10.875 8.29688 10.875 7.4375C10.875 6.57812 10.0336 5.875 9 5.875C7.96641 5.875 7.125 6.57812 7.125 7.4375V7.75C7.125 7.91576 7.05916 8.07473 6.94195 8.19194C6.82474 8.30915 6.66576 8.375 6.5 8.375C6.33424 8.375 6.17527 8.30915 6.05806 8.19194C5.94085 8.07473 5.875 7.91576 5.875 7.75V7.4375C5.875 5.88672 7.27657 4.625 9 4.625C10.7234 4.625 12.125 5.88672 12.125 7.4375C12.125 8.79531 11.05 9.93203 9.625 10.1938Z" />
            </svg>
            <div class="text-sm z-50">
                Manque d'information
            </div>
        </div>
    </div>
    

<!-- vote neutral -->
    <div class="grid place-content-center h-10  relative" *ngIf="isVoteNeutral">
        <input type="radio" tabindex="0" name="socVote" id="neutral" [value]="3"
            class="z-0 h-full w-full peer appearance-none bg-info absolute rounded-lg ">
        <div class="flex flex-row gap-1">

            <svg width="14" viewBox="0 0 16 16" class="z-50 fill-text peer-checked:fill-white-200 pointer-events-none"
                xmlns="http://www.w3.org/2000/svg" aria-labelledby="icône vote neutre pour sociétal">
                <path
                    d="M14.25 0.5H1.75C1.41848 0.5 1.10054 0.631696 0.866116 0.866116C0.631696 1.10054 0.5 1.41848 0.5 1.75V14.25C0.5 14.5815 0.631696 14.8995 0.866116 15.1339C1.10054 15.3683 1.41848 15.5 1.75 15.5H14.25C14.5815 15.5 14.8995 15.3683 15.1339 15.1339C15.3683 14.8995 15.5 14.5815 15.5 14.25V1.75C15.5 1.41848 15.3683 1.10054 15.1339 0.866116C14.8995 0.631696 14.5815 0.5 14.25 0.5ZM12.375 10.5H3.625C3.45924 10.5 3.30027 10.4342 3.18306 10.3169C3.06585 10.1997 3 10.0408 3 9.875C3 9.70924 3.06585 9.55027 3.18306 9.43306C3.30027 9.31585 3.45924 9.25 3.625 9.25H12.375C12.5408 9.25 12.6997 9.31585 12.8169 9.43306C12.9342 9.55027 13 9.70924 13 9.875C13 10.0408 12.9342 10.1997 12.8169 10.3169C12.6997 10.4342 12.5408 10.5 12.375 10.5ZM12.375 6.75H3.625C3.45924 6.75 3.30027 6.68415 3.18306 6.56694C3.06585 6.44973 3 6.29076 3 6.125C3 5.95924 3.06585 5.80027 3.18306 5.68306C3.30027 5.56585 3.45924 5.5 3.625 5.5H12.375C12.5408 5.5 12.6997 5.56585 12.8169 5.68306C12.9342 5.80027 13 5.95924 13 6.125C13 6.29076 12.9342 6.44973 12.8169 6.56694C12.6997 6.68415 12.5408 6.75 12.375 6.75Z" />
            </svg>
            <div class="text-sm z-50">
                Neutre
            </div>
        </div>
    </div>