<div *ngIf="cards?.length" class="grid grid-cols-1 items-center justify-center">
  <h4 class="font-bold text-center py-3">Besoin d'aide pour estimer ?</h4>
  <div class="flex flex-row items-center justify-center">
    <div class="flex flex-col items-center justify-center">
      <div class="flex flex-row m-4">
        <div *ngFor="let card of cardsToDisplay">
          <app-card [card]="card"></app-card>
        </div>

      </div>
      <div *ngIf="!isOnlyOnePage" class="grid grid-cols-2 gap-5">
        <button type="button" class="bg-white-400 border-basic border-2 rounded-lg p-3 text-sm
        disabled:border-disabled"
          (click)="onPreviousClick()" tabindex="0">
          <div class="flex flex-row justify-center">
              < Précédent
          </div>
        </button>
        <button type="button" class="bg-white-400 border-basic border-2 rounded-lg p-3 text-sm disabled:border-disabled" (click)="onNextClick()"
          tabindex="0">
          <div class="flex flex-row justify-center">
              Suivant >

          </div>

        </button>

      </div>
    </div>

  </div>
</div>
<div *ngIf="!cards?.length"
  class="grid grid-cols-1 items-center justify-center w-full h-full font-bold text-center py-3">
  Chargement des cartes...
</div>