import { Component, Input } from '@angular/core';
import { Card } from 'src/app/models/card.interface';
import { CardsType } from 'src/app/models/enum/cardstype.enum';
import { CardService } from 'src/app/services/card.service';
import { LocalStorageService } from 'src/app/services/local-storage.service';

@Component({
  selector: 'app-cards-carrousel',
  templateUrl: './cards-carrousel.component.html',
  styleUrls: ['./cards-carrousel.component.css']
})
export class CardsCarrouselComponent {

  @Input() cardsTypeToDisplay : CardsType | null = null; //if it's null display all cards

  cards: Card[] = [];
  currentCardIdx: number = 0;

  numberOfElementToDisplay : number = 4;

  constructor(private localStorageService: LocalStorageService, private cardService: CardService) {
    this.fetchCards();
  }
  


  onPreviousClick() {
    this.currentCardIdx = 
      Math.ceil(
        (
          (
            this.currentCardIdx - this.numberOfElementToDisplay + this.cards.length
          ) % this.cards.length 
        )/this.numberOfElementToDisplay)
      * this.numberOfElementToDisplay
  }

  onNextClick() {
    this.currentCardIdx = Math.min(this.currentCardIdx + this.numberOfElementToDisplay,this.cards.length) % this.cards.length;
  }

  get isOnlyOnePage(){
    
    return this.cards.length <= this.numberOfElementToDisplay;
  }

  fetchCards() {
    this.cards = [];
    if (this.cards.length < 1) {
      this.cardService.getAllCards().subscribe(res => {
        this.cards = res.cards.filter(
          card => card.type == (this.cardsTypeToDisplay == null ? card.type : this.cardsTypeToDisplay)
        );
        this.localStorageService.setCards(this.cards)
      });
    }
  }



  get cardsToDisplay(){
    return this.cards.slice(this.currentCardIdx,this.currentCardIdx+this.numberOfElementToDisplay);
  }
}
