import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment';
import { Room } from '../models/room.interface';

@Injectable({
  providedIn: 'root'
})
export class RoomService {

  constructor(private http: HttpClient) { }

  createRoom(username: string,roomVoteType : { environnemental : boolean,societal : boolean } ): Observable<Room> {
    return this.http.post<Room>(
      environment.apiUrl + 'room', {username , roomVoteType}
    );
  }

  joinRoom(roomId: string, userName: string): Observable<Room> {
    return this.http.get<Room>(
      environment.apiUrl + 'room/' + roomId + '/' + userName
    );
  }
}
