import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';
import { VoteDecision } from '../models/enum/voteDecision.enum';
import { Room } from '../models/room.interface';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class VoteService {

  constructor(private http: HttpClient) { }

  vote(roomId: string, username: string, envVote: VoteDecision | null, socVote: VoteDecision | null): Observable<Room> {
    return this.http.post<Room>(
      environment.apiUrl + 'vote', {roomId: roomId, username: username, envVote: envVote, socVote: socVote}
    );
  }

  revealCards(roomId: string) {
    return this.http.get<void>(
      environment.apiUrl + 'vote/' + roomId
    );
  }

  startVote(room: Room) {
    return this.http.put<void>(
      environment.apiUrl + 'vote',{ room }
    );
  }

  resetVote(roomId: string) {
    return this.http.get<void>(
      environment.apiUrl + 'vote/reset/' + roomId
    )
  }
}
