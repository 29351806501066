import { Component, Inject } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { User } from 'src/app/models/user.interface';

@Component({
  selector: 'app-users-popup',
  templateUrl: './users-popup.component.html',
  styleUrls: ['./users-popup.component.css']
})
export class UsersPopupComponent {
  users: User[] = [];

  constructor(
    public dialogRef: MatDialogRef<UsersPopupComponent>,
    @Inject(MAT_DIALOG_DATA) public data: {users: User[]}) {
      this.users = data.users;
    }

  closeParticipants(): void {
    this.dialogRef.close();
  }
}
