<div>
  <div class="mb-4">
    <p class="text-s md:text-xs uppercase pt-3 md:pt-5 text-center">
      Vote de la salle {{room.id}}
    </p>
    <h1 class="text-4xl font-bold text-basic text-center mt-3">
      Vote à lancer
    </h1>
  </div>
  
  <div>
    <p class="w-full max-w-screen-md pt-4 md:pt-8 text-center">
      <span class="text-base">Nom de la salle : </span><span class="uppercase">{{room.id}}</span>
    </p>
    <div class="w-full max-w-screen-md mt-3">
      <button tabindex="0" type="button" class=" border border-basic w-full max-w-screen-md h-14 font-bold text-base text-center rounded-lg flex flex-row items-center justify-center"
      (click)="copyRoomLink()">
      <svg width="17" height="16" viewBox="0 0 17 16" fill="none" xmlns="http://www.w3.org/2000/svg"
      aria-labelledby="icône pour copier le lien de la salle">
        <path d="M15.375 0.1875H5.375C5.12636 0.1875 4.8879 0.286272 4.71209 0.462087C4.53627 0.637903 4.4375 0.87636 4.4375 1.125V3.9375H1.625C1.37636 3.9375 1.1379 4.03627 0.962087 4.21209C0.786272 4.3879 0.6875 4.62636 0.6875 4.875V14.875C0.6875 15.1236 0.786272 15.3621 0.962087 15.5379C1.1379 15.7137 1.37636 15.8125 1.625 15.8125H11.625C11.8736 15.8125 12.1121 15.7137 12.2879 15.5379C12.4637 15.3621 12.5625 15.1236 12.5625 14.875V12.0625H15.375C15.6236 12.0625 15.8621 11.9637 16.0379 11.7879C16.2137 11.6121 16.3125 11.3736 16.3125 11.125V1.125C16.3125 0.87636 16.2137 0.637903 16.0379 0.462087C15.8621 0.286272 15.6236 0.1875 15.375 0.1875ZM10.6875 13.9375H2.5625V5.8125H10.6875V13.9375ZM14.4375 10.1875H12.5625V4.875C12.5625 4.62636 12.4637 4.3879 12.2879 4.21209C12.1121 4.03627 11.8736 3.9375 11.625 3.9375H6.3125V2.0625H14.4375V10.1875Z" fill="#202020"/>
      </svg>
      <span class="pl-2.5">Lien de la salle</span></button>
    </div>
  </div>
  
  
  
  
  <section class="grow w-full h-full items-center justify-center max-w-screen-md  pb-5">
    <p class="w-full py-6 md:py-7">L'équipe a choisi d'évaluer l'impact</p>
      <ul class="grid grid-cols-1 w-full gap-y-7 md:gap-y-9">
        <li class="flex flex-row gap-2" *ngIf="isRoomVoteTypeSoc">
          <label for="socCheckbox" class="font-bold flex flex-row gap-2 text-center">
            <svg width="18" height="23" viewBox="0 0 18 23" xmlns="http://www.w3.org/2000/svg" aria-labelledby="humain"
            class="fill-basic">
              <path d="M6.37496 3.375C6.37496 2.85583 6.52891 2.34831 6.81735 1.91663C7.10579 1.48495 7.51576 1.1485 7.99541 0.949817C8.47507 0.751137 9.00287 0.699154 9.51207 0.80044C10.0213 0.901726 10.489 1.15173 10.8561 1.51885C11.2232 1.88596 11.4732 2.35369 11.5745 2.86289C11.6758 3.37209 11.6238 3.89989 11.4251 4.37955C11.2265 4.8592 10.89 5.26917 10.4583 5.55761C10.0266 5.84605 9.51913 6 8.99996 6C8.30376 6 7.63608 5.72344 7.1438 5.23116C6.65152 4.73887 6.37496 4.07119 6.37496 3.375ZM17.1956 13.1981L12.9534 8.38875C12.7071 8.10943 12.4041 7.88572 12.0647 7.73249C11.7252 7.57926 11.3571 7.50001 10.9846 7.5H7.01527C6.64284 7.50001 6.27468 7.57926 5.93524 7.73249C5.5958 7.88572 5.29285 8.10943 5.04652 8.38875L0.804332 13.1981C0.528871 13.48 0.375397 13.8589 0.377089 14.253C0.378781 14.6471 0.535502 15.0247 0.813372 15.3042C1.09124 15.5836 1.46794 15.7425 1.86202 15.7465C2.2561 15.7504 2.63591 15.5992 2.91933 15.3253L5.34371 13.3781L3.49683 20.3981C3.33615 20.7588 3.32419 21.1684 3.46355 21.5378C3.60291 21.9073 3.88234 22.2069 4.24122 22.3717C4.60009 22.5364 5.00946 22.553 5.38049 22.4179C5.75152 22.2827 6.05428 22.0067 6.22308 21.6497L8.99996 16.875L11.7731 21.6563C11.9419 22.0132 12.2446 22.2893 12.6157 22.4244C12.9867 22.5596 13.3961 22.543 13.7549 22.3783C14.1138 22.2135 14.3933 21.9139 14.5326 21.5444C14.672 21.1749 14.66 20.7654 14.4993 20.4047L12.6562 13.3781L15.0843 15.3253C15.3678 15.5992 15.7476 15.7504 16.1416 15.7465C16.5357 15.7425 16.9124 15.5836 17.1903 15.3042C17.4682 15.0247 17.6249 14.6471 17.6266 14.253C17.6283 13.8589 17.4748 13.48 17.1993 13.1981H17.1956Z"/>
            </svg>
            Sociétal
          </label>
        </li>
        <li class="flex flex-row gap-2" *ngIf="isRoomVoteTypeEnv">
          <label for="envCheckbox" class="font-bold flex flex-row gap-2">
            <svg width="24" height="19" viewBox="0 0 24 19" xmlns="http://www.w3.org/2000/svg" aria-labelledby="fleur"
            class="translate-y-1 fill-basic">
              <path d="M19.2572 11.1629C18.358 11.7094 17.3253 11.9969 16.2731 11.9935C15.3916 11.9865 14.5201 11.8062 13.7081 11.4629C13.0823 12.3463 12.7474 13.4028 12.75 14.4854V17.2501C12.7502 17.3529 12.7293 17.4546 12.6885 17.549C12.6478 17.6434 12.5881 17.7284 12.5131 17.7988C12.4381 17.8691 12.3494 17.9233 12.2526 17.9579C12.1558 17.9925 12.0529 18.0069 11.9503 18.0001C11.7575 17.9833 11.5782 17.8942 11.4483 17.7508C11.3185 17.6074 11.2476 17.4201 11.25 17.2266V16.0604L7.62937 12.4397C7.09115 12.6405 6.52193 12.7456 5.9475 12.7501C5.15669 12.752 4.38069 12.5356 3.705 12.1247C1.66218 10.8835 0.562497 8.02693 0.775309 4.48037C0.786024 4.29689 0.863741 4.12373 0.993703 3.99376C1.12367 3.8638 1.29683 3.78608 1.48031 3.77537C5.02687 3.56631 7.88343 4.66224 9.12093 6.70506C9.60713 7.50576 9.8194 8.443 9.72562 9.37506C9.71979 9.44727 9.69317 9.51625 9.64897 9.57366C9.60477 9.63106 9.54489 9.67443 9.47656 9.69853C9.40824 9.72263 9.3344 9.72642 9.26397 9.70944C9.19353 9.69247 9.12953 9.65545 9.07968 9.60287L7.27968 7.7185C7.13789 7.58378 6.94907 7.50979 6.7535 7.51229C6.55793 7.51479 6.37108 7.5936 6.23277 7.7319C6.09447 7.8702 6.01567 8.05706 6.01317 8.25263C6.01066 8.4482 6.08466 8.63701 6.21937 8.77881L11.2706 13.9585C11.2762 13.8854 11.2828 13.8122 11.2903 13.7401C11.4543 12.3495 12.0679 11.0506 13.0378 10.0407L17.7806 5.02881C17.9214 4.8882 18.0005 4.69745 18.0005 4.49851C18.0006 4.29958 17.9217 4.10876 17.7811 3.96803C17.6405 3.8273 17.4497 3.74819 17.2508 3.7481C17.0519 3.74801 16.861 3.82695 16.7203 3.96756L12.1266 8.82568C12.0806 8.87439 12.0224 8.90989 11.958 8.92849C11.8937 8.9471 11.8255 8.94813 11.7607 8.93148C11.6958 8.91483 11.6365 8.8811 11.5891 8.83381C11.5417 8.78652 11.5078 8.72739 11.4909 8.66256C11.0466 7.02381 11.2425 5.39256 12.0909 3.99193C13.7653 1.22818 17.6616 -0.251192 22.5141 0.0338077C22.6975 0.0445228 22.8707 0.122239 23.0007 0.252202C23.1306 0.382165 23.2083 0.555325 23.2191 0.738808C23.5003 5.59225 22.0209 9.4885 19.2572 11.1629Z"/>
            </svg>
            Environnemental
          </label>
        </li>
      </ul>
  </section>
  
  <section class="w-full  items-center justify-center">
    <button *ngIf="currentUser.type === 'maitreDuJeu'" tabindex="0" type="button" class="w-full max-w-screen-md h-14 font-bold text-base bg-basic rounded-lg items-center justify-center disabled:opacity-50"
    (click)="startVote()">Lancer le vote
    </button>
  </section>
  
  
</div>