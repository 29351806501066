<div class="w-[17.25rem] h-fit bg-white-400 rounded-r-[20px] rounded-bl-[1.25rem]">
  <header class="flex flex-row pt-3 px-5 pb-2 justify-between">
    <h1 class="font-bold">Les participants</h1>
    <button type="button" (click)="closeParticipants()" tabindex="0">
      <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg"
      aria-labelledby="icône croix qui permet de fermer la liste des participants">
        <path d="M19.7075 18.2925C19.8004 18.3854 19.8741 18.4957 19.9244 18.6171C19.9747 18.7385 20.0006 18.8686 20.0006 19C20.0006 19.1314 19.9747 19.2615 19.9244 19.3829C19.8741 19.5043 19.8004 19.6146 19.7075 19.7075C19.6146 19.8004 19.5043 19.8741 19.3829 19.9244C19.2615 19.9747 19.1314 20.0006 19 20.0006C18.8686 20.0006 18.7385 19.9747 18.6171 19.9244C18.4957 19.8741 18.3854 19.8004 18.2925 19.7075L10 11.4138L1.70751 19.7075C1.51987 19.8951 1.26537 20.0006 1.00001 20.0006C0.734643 20.0006 0.480147 19.8951 0.292507 19.7075C0.104866 19.5199 -0.000549311 19.2654 -0.000549316 19C-0.000549322 18.7346 0.104866 18.4801 0.292507 18.2925L8.58626 10L0.292507 1.70751C0.104866 1.51987 -0.000549316 1.26537 -0.000549316 1.00001C-0.000549316 0.734643 0.104866 0.480147 0.292507 0.292507C0.480147 0.104866 0.734643 -0.000549316 1.00001 -0.000549316C1.26537 -0.000549316 1.51987 0.104866 1.70751 0.292507L10 8.58626L18.2925 0.292507C18.4801 0.104866 18.7346 -0.000549322 19 -0.000549316C19.2654 -0.000549311 19.5199 0.104866 19.7075 0.292507C19.8951 0.480147 20.0006 0.734643 20.0006 1.00001C20.0006 1.26537 19.8951 1.51987 19.7075 1.70751L11.4138 10L19.7075 18.2925Z" fill="#343330"/>
        </svg>
    </button>
  </header>
  <div *ngFor="let user of users" class="flex flex-col gap-4 pb-4">
    <app-user [user]="user"></app-user>
  </div>
</div>
