import { Injectable } from '@angular/core';
import { Room } from '../models/room.interface';
import { User } from '../models/user.interface';
import { Card } from '../models/card.interface';
import { RoomStatus } from '../models/enum/roomStatus.enum';
import { UserRole } from '../models/enum/userRole.enum';

@Injectable({
  providedIn: 'root'
})
export class LocalStorageService {

  constructor() { }

  getLocalStorageUsername() {
    const userInStorage = localStorage.getItem('currentUser');
    if (userInStorage) {
      const user: User = JSON.parse(userInStorage);
      return user.username;
    } else {
      return '';
    }
  }

  getLocalStorageCurrentUser() {
    const userInStorage = localStorage.getItem('currentUser');
    if (userInStorage) {
      const user: User = JSON.parse(userInStorage);
      return user;
    } else {
      return {username: '', type: UserRole.player, socVote: null, envVote: null };
    }
  }

  getLocalStorageCards() {
    const cardsInStorage = localStorage.getItem('cards');
    if (cardsInStorage) {
      const cards: Card[] = JSON.parse(cardsInStorage);
      return cards;
    } else {
      return [];
    }
  }

  getLocalStorageRoom() {
    const roomInStorage = localStorage.getItem('room');
    if (roomInStorage) {
      const room : Room = JSON.parse(roomInStorage);
      
      return room
    } else {
      return {id: '', status: RoomStatus.pending, users: [], roomVoteType : {environnemental: false, societal: false}};
    }
  }

  setCards(cards: Card[]) {
    localStorage.setItem('cards', JSON.stringify(cards));
  }

  setUser(user: User) {
    localStorage.setItem('currentUser', JSON.stringify(user));
  }

  setRoom(room: Room) {
    localStorage.setItem('room', JSON.stringify(room));
  }

  removeRoomAndUserFromLocalStorage() {
    localStorage.removeItem('room');
    localStorage.removeItem('currentUser');
  }



}
