import { Component, Input } from '@angular/core';
import { VoteDecision } from 'src/app/models/enum/voteDecision.enum';

@Component({
  selector: 'app-vote-element',
  templateUrl: './vote-element.component.html',
  styleUrls: ['./vote-element.component.css']
})
export class VoteElementComponent {
  @Input() vote: VoteDecision | null = null;

  get isVotePositive(){
    return this.vote === VoteDecision.positive
  }
  get isVoteNegative(){
    return this.vote === VoteDecision.negative
  }
  get isVoteIdk(){
    return this.vote === VoteDecision.idk 
  }
  get isVoteNeutral(){
    return this.vote === VoteDecision.neutral
  }

}
