import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { HomeComponent } from './home/home.component';
import { RoomComponent } from './room/room.component';
import { FormsModule } from '@angular/forms';
import { CardComponent } from './room/cards-carrousel/card/card.component';
import { UserComponent } from './room/users-popup/user/user.component';
import { VotingSectionComponent } from './room/voting-section/voting-section.component';
import { UsersPopupComponent } from './room/users-popup/users-popup.component';
import { RoomAccueilComponent } from './room/room-accueil/room-accueil.component';
import { CardsCarrouselComponent } from './room/cards-carrousel/cards-carrousel.component';
import { HasVotedSectionComponent } from './room/has-voted-section/has-voted-section.component';
import { VoteRevealedComponent } from './room/vote-revealed/vote-revealed.component';
import { UserVoteResultComponent } from './room/vote-revealed/user-vote-result/user-vote-result.component';
import { DisplayDecisionComponent } from './room/vote-revealed/user-vote-result/display-decision/display-decision.component';
import { VoteElementComponent } from '../component/vote-element/vote-element.component';



@NgModule({
  declarations: [
    HomeComponent,
    RoomComponent,
    CardComponent,
    UserComponent,
    VotingSectionComponent,
    UsersPopupComponent,
    RoomAccueilComponent,
    CardsCarrouselComponent,
    HasVotedSectionComponent,
    VoteRevealedComponent,
    UserVoteResultComponent,
    DisplayDecisionComponent,
    VoteElementComponent
  ],
  imports: [
    CommonModule,
    FormsModule
  ]
})
export class PageModule { }
