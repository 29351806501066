<div class="w-[20rem] h-fit bg-white-400 rounded-r-[20px] rounded-bl-[1.25rem]">
  <header class="flex flex-row pt-3 px-5 pb-2 justify-between">
    <h1 class="font-bold">Les paramètres</h1>
    <button type="button" (click)="closeConfig()" tabindex="0">
      <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg"
      aria-labelledby="icône croix qui permet de fermer la liste des participants">
        <path d="M19.7075 18.2925C19.8004 18.3854 19.8741 18.4957 19.9244 18.6171C19.9747 18.7385 20.0006 18.8686 20.0006 19C20.0006 19.1314 19.9747 19.2615 19.9244 19.3829C19.8741 19.5043 19.8004 19.6146 19.7075 19.7075C19.6146 19.8004 19.5043 19.8741 19.3829 19.9244C19.2615 19.9747 19.1314 20.0006 19 20.0006C18.8686 20.0006 18.7385 19.9747 18.6171 19.9244C18.4957 19.8741 18.3854 19.8004 18.2925 19.7075L10 11.4138L1.70751 19.7075C1.51987 19.8951 1.26537 20.0006 1.00001 20.0006C0.734643 20.0006 0.480147 19.8951 0.292507 19.7075C0.104866 19.5199 -0.000549311 19.2654 -0.000549316 19C-0.000549322 18.7346 0.104866 18.4801 0.292507 18.2925L8.58626 10L0.292507 1.70751C0.104866 1.51987 -0.000549316 1.26537 -0.000549316 1.00001C-0.000549316 0.734643 0.104866 0.480147 0.292507 0.292507C0.480147 0.104866 0.734643 -0.000549316 1.00001 -0.000549316C1.26537 -0.000549316 1.51987 0.104866 1.70751 0.292507L10 8.58626L18.2925 0.292507C18.4801 0.104866 18.7346 -0.000549322 19 -0.000549316C19.2654 -0.000549311 19.5199 0.104866 19.7075 0.292507C19.8951 0.480147 20.0006 0.734643 20.0006 1.00001C20.0006 1.26537 19.8951 1.51987 19.7075 1.70751L11.4138 10L19.7075 18.2925Z" fill="#343330"/>
        </svg>
    </button>
  </header>
  <div>
    <div class="w-fulljustify-items-center mt-5 px-6">
      <button tabindex="0" type="button" (click)="copyRoomLink()" id="openConfigButton">
        <div class="flex flex-row gap-3">
          <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20" fill="none">
            <path d="M16.875 2.5H6.875C6.70924 2.5 6.55027 2.56585 6.43306 2.68306C6.31585 2.80027 6.25 2.95924 6.25 3.125V6.25H3.125C2.95924 6.25 2.80027 6.31585 2.68306 6.43306C2.56585 6.55027 2.5 6.70924 2.5 6.875V16.875C2.5 17.0408 2.56585 17.1997 2.68306 17.3169C2.80027 17.4342 2.95924 17.5 3.125 17.5H13.125C13.2908 17.5 13.4497 17.4342 13.5669 17.3169C13.6842 17.1997 13.75 17.0408 13.75 16.875V13.75H16.875C17.0408 13.75 17.1997 13.6842 17.3169 13.5669C17.4342 13.4497 17.5 13.2908 17.5 13.125V3.125C17.5 2.95924 17.4342 2.80027 17.3169 2.68306C17.1997 2.56585 17.0408 2.5 16.875 2.5ZM16.25 12.5H13.75V6.875C13.75 6.70924 13.6842 6.55027 13.5669 6.43306C13.4497 6.31585 13.2908 6.25 13.125 6.25H7.5V3.75H16.25V12.5Z" fill="#063A5B"/>
          </svg>
        copier le lien de la salle
      </div>
      </button>
    </div>

    <div class="w-full justify-items-center py-5 px-6">
      <button tabindex="0" type="button" (click)="returnHome()" id="openConfigButton">
        <div class="flex flex-row gap-3">
          <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20" fill="none">
            <path d="M8.75 16.875C8.75 17.0408 8.68415 17.1997 8.56694 17.3169C8.44973 17.4342 8.29076 17.5 8.125 17.5H3.75C3.41848 17.5 3.10054 17.3683 2.86612 17.1339C2.6317 16.8995 2.5 16.5815 2.5 16.25V3.75C2.5 3.41848 2.6317 3.10054 2.86612 2.86612C3.10054 2.6317 3.41848 2.5 3.75 2.5H8.125C8.29076 2.5 8.44973 2.56585 8.56694 2.68306C8.68415 2.80027 8.75 2.95924 8.75 3.125C8.75 3.29076 8.68415 3.44973 8.56694 3.56694C8.44973 3.68415 8.29076 3.75 8.125 3.75H3.75V16.25H8.125C8.29076 16.25 8.44973 16.3158 8.56694 16.4331C8.68415 16.5503 8.75 16.7092 8.75 16.875ZM17.3172 9.55781L14.1922 6.43281C14.1048 6.34531 13.9934 6.2857 13.8721 6.26154C13.7508 6.23739 13.625 6.24977 13.5108 6.29711C13.3965 6.34446 13.2988 6.42464 13.2302 6.52751C13.1615 6.63038 13.1249 6.75132 13.125 6.875V9.375H8.125C7.95924 9.375 7.80027 9.44085 7.68306 9.55806C7.56585 9.67527 7.5 9.83424 7.5 10C7.5 10.1658 7.56585 10.3247 7.68306 10.4419C7.80027 10.5592 7.95924 10.625 8.125 10.625H13.125V13.125C13.1249 13.2487 13.1615 13.3696 13.2302 13.4725C13.2988 13.5754 13.3965 13.6555 13.5108 13.7029C13.625 13.7502 13.7508 13.7626 13.8721 13.7385C13.9934 13.7143 14.1048 13.6547 14.1922 13.5672L17.3172 10.4422C17.3753 10.3841 17.4214 10.3152 17.4529 10.2393C17.4843 10.1635 17.5005 10.0821 17.5005 10C17.5005 9.91787 17.4843 9.83654 17.4529 9.76066C17.4214 9.68479 17.3753 9.61586 17.3172 9.55781Z" fill="#063A5B"/>
          </svg>
        Sortir de la salle
      </div>
      </button>
    </div>
  </div>
</div>
