import { Component, EventEmitter, Input, Output } from '@angular/core';
import { CardsType } from 'src/app/models/enum/cardstype.enum';
import { RoomStatus } from 'src/app/models/enum/roomStatus.enum';
import { UserRole } from 'src/app/models/enum/userRole.enum';
import { Room } from 'src/app/models/room.interface';
import { User } from 'src/app/models/user.interface';

@Component({
  selector: 'app-vote-revealed',
  templateUrl: './vote-revealed.component.html',
  styleUrls: ['./vote-revealed.component.css']
})
export class VoteRevealedComponent {
  @Input() room: Room = {id: '', status: RoomStatus.pending, users: [], roomVoteType: {environnemental: false, societal: false}};
  @Input() currentUser: User = {username: '', type: UserRole.player, envVote: null, socVote: null};
  @Output() resetEvent = new EventEmitter();
  showHelp: boolean = false;

  reset() {
    this.resetEvent.emit();
  }

  get cardsTypeToDisplay(){
    if(this.room.roomVoteType.environnemental && this.room.roomVoteType.societal)
    {
      return null
    }
    return this.room.roomVoteType.environnemental ? CardsType.environnementale : CardsType.societale;
  }

  showHelpCards() {
    this.showHelp = !this.showHelp;
  }
}
