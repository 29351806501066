import { Component, Input } from '@angular/core';
import { User } from 'src/app/models/user.interface';
import { LocalStorageService } from 'src/app/services/local-storage.service';

@Component({
  selector: 'app-user',
  templateUrl: './user.component.html',
  styleUrls: ['./user.component.css']
})
export class UserComponent {
  @Input() user!: User;

  constructor(private localStorageService: LocalStorageService) {
  }

  get isUserSelf(): boolean {
    return this.user.username === this.localStorageService.getLocalStorageUsername();
  }
}
