import { Component, EventEmitter, Input, Output } from '@angular/core';
import { Router } from '@angular/router';
import { RoomStatus } from 'src/app/models/enum/roomStatus.enum';
import { UserRole } from 'src/app/models/enum/userRole.enum';
import { Room } from 'src/app/models/room.interface';
import { User } from 'src/app/models/user.interface';

@Component({
  selector: 'app-room-accueil',
  templateUrl: './room-accueil.component.html',
  styleUrls: ['./room-accueil.component.css']
})
export class RoomAccueilComponent {
  @Input() room : Room = {id: '', status: RoomStatus.pending, users: [], roomVoteType: {environnemental: false, societal: false}};
  @Input() currentUser: User = {username: '', type: UserRole.player, envVote: null, socVote: null};
  @Output() startVoteEvent = new EventEmitter();

  constructor(private router: Router) {}

  copyRoomLink(): void {
    navigator.clipboard.writeText(window.location.href).catch(() => {
      console.error("Unable to copy text");
    });
  }

  returnHome() {
    this.router.navigate(['']);
  }

  startVote() {
    this.startVoteEvent.emit();
  }

  get isRoomVoteTypeEnv(){
    return this.room.roomVoteType.environnemental
  }

  get isRoomVoteTypeSoc(){
    return this.room.roomVoteType.societal
  }
}
