import { Component, OnDestroy, OnInit } from '@angular/core';
import { ReplaySubject, takeUntil, tap } from 'rxjs';
import { MatDialog } from '@angular/material/dialog';
import { Card } from 'src/app/models/card.interface';
import { RoomStatus } from 'src/app/models/enum/roomStatus.enum';
import { VoteDecision } from 'src/app/models/enum/voteDecision.enum';
import { UserRole } from 'src/app/models/enum/userRole.enum';
import { Room } from 'src/app/models/room.interface';
import { LocalStorageService } from 'src/app/services/local-storage.service';
import { SocketService } from 'src/app/services/socket.service';
import { VoteService } from 'src/app/services/vote.service';
import { UsersPopupComponent } from './users-popup/users-popup.component';
import { User } from 'src/app/models/user.interface';
import { Router } from '@angular/router';
import { ConfigPopupComponent } from '../../component/config-popup/config-popup.component';

enum ActionTypes {
  joinRoom = 'joinRoom',
  createRoom = 'createRoom',
  leaveRoom = 'leaveRoom',
  vote = 'voteCards',
  startVote = 'startVote',
  reveal = 'revealCards',
  reset = 'resetRoom'
}

@Component({
  selector: 'app-room',
  templateUrl: './room.component.html',
  styleUrls: ['./room.component.css']
})
export class RoomComponent implements OnInit, OnDestroy {
  room: Room = {id: '', status: RoomStatus.pending, users: [], roomVoteType: {environnemental: false, societal: false}};
  currentUser : User = {username: '', type: UserRole.player, socVote: null, envVote: null };
  cards: Card[] = [];
  private destroyed$: ReplaySubject<boolean> = new ReplaySubject(1);

  constructor(private voteService: VoteService,
              private socketService: SocketService,
              private localStorageService: LocalStorageService,
              private matDialog: MatDialog,
              private router: Router) {
    
  }

  ngOnInit(): void {
    this.currentUser = this.localStorageService.getLocalStorageCurrentUser();
    this.room = this.localStorageService.getLocalStorageRoom();
    this.userJoinsRoom();
    this.userLeavesRoom();
    this.userVotes();
    this.reveal();
    this.voteStarted();
    this.resetRoom()
  }

  ngOnDestroy(): void {
    this.localStorageService.removeRoomAndUserFromLocalStorage();
    this.destroyed$.next(true);
    this.destroyed$.complete();
  }

  returnHome() {
    this.router.navigate(['']);
  }

  userJoinsRoom() {
    this.socketService.fromEvent<{action: string, username: string}>(this.room.id + ActionTypes.joinRoom).pipe(
      tap((message) => {
        if (message.action !== ActionTypes.joinRoom) {
          throw new Error;
        };
        this.room.users.push({username: message.username, type: UserRole.player, envVote: null, socVote: null});
      }),
      takeUntil(this.destroyed$))
    .subscribe();
  }

  userLeavesRoom() {
    this.socketService.fromEvent<{action: string, username: string}>(this.room.id + ActionTypes.leaveRoom).pipe(
      tap((message) => {
        if (message.action !== ActionTypes.leaveRoom) {
          throw new Error;
        };
        const indexOfUser = this.room.users.findIndex(user => user.username === message.username);
        this.room.users.splice(indexOfUser, 1);
      }),
      takeUntil(this.destroyed$))
    .subscribe();
  }

  userVotes() {
    this.socketService.fromEvent<{action: string, username: string, socVote: VoteDecision | null, envVote: VoteDecision | null }>(this.room.id + ActionTypes.vote).pipe(
      tap((message) => {
        if (message.action !== ActionTypes.vote) {
          throw new Error
          };
          const indexOfUser = this.room.users.findIndex(user => user.username === message.username);
          this.room.users[indexOfUser] = {
            ...this.room.users[indexOfUser],
            envVote: message.envVote,
            socVote: message.socVote
        };
        this.localStorageService.setRoom(this.room);
      }),
      takeUntil(this.destroyed$))
    .subscribe();
  }

  voteDecisionEvent(vote: {envVote: VoteDecision | null, socVote: VoteDecision | null}) {
    const userIndex = this.room.users.findIndex(user => user.username === this.currentUser.username);
    this.currentUser.envVote = vote.envVote;
    this.currentUser.socVote = vote.socVote;
    this.localStorageService.setUser(this.currentUser);
    if (userIndex !== -1) {
      this.voteService.vote(this.room.id, this.currentUser.username, this.currentUser.envVote, this.currentUser.socVote).pipe(
        tap(room => {
          this.room = room;
          this.room.status = RoomStatus.hasVoted;
          this.localStorageService.setRoom(this.room);
        })
      )
      .subscribe();
    }
  }

  voteStarted() {
    this.socketService.fromEvent<{action: string, room: Room}>(this.room.id + ActionTypes.startVote).pipe(
      tap((message) => {
        if (message.action !== ActionTypes.startVote) {
          throw new Error;
        };
        this.room = message.room;
        for (let user of this.room.users) {
          user.socVote = null;
          user.envVote = null;
        };
        this.localStorageService.setRoom(this.room);
      }),
      takeUntil(this.destroyed$))
    .subscribe()
  }

  resetRoom() {
    this.socketService.fromEvent<{action: string}>(this.room.id + ActionTypes.reset).pipe(
      tap((message) => {
        if (message.action !== ActionTypes.reset) {
          throw new Error;
        };
        this.room.status = RoomStatus.pending;
        this.localStorageService.setRoom(this.room);
      }),
      takeUntil(this.destroyed$))
    .subscribe()
  }

  startVote() {
    this.voteService.startVote(this.room).subscribe();
  }

  reset() {
    this.voteService.resetVote(this.room.id).subscribe();
  }

  showCards() {
    this.voteService.revealCards(this.room.id).subscribe();
  }


  reveal() {
    this.socketService.fromEvent<{action: string, room: Room}>(this.room.id + ActionTypes.reveal).pipe(
      tap((message) => {
        this.room.status = RoomStatus.voteRevealed;
        if (message.action !== ActionTypes.reveal) {
          throw new Error;
        };
      }),
      takeUntil(this.destroyed$))
    .subscribe()
  }

  openParticipants(): void {
    const button = document.getElementById('openParticipantsButton');
    if (button) {
      const buttonPosition = button.getBoundingClientRect();
      const topPosition = (buttonPosition.top + 40).toString() + 'px';
      const leftPosition = buttonPosition.left.toString() + 'px';
      this.matDialog.open(UsersPopupComponent, {
        data: {users: this.room.users}, position: { top: topPosition, left: leftPosition }
      });
    }
  }


openConfigMenu(): void {
  const button = document.getElementById('openConfigButton');
  if (button) {
    const buttonPosition = button.getBoundingClientRect();
    const topPosition = (buttonPosition.top + 40).toString() + 'px';
    const leftPosition = buttonPosition.left.toString() + 'px';
    this.matDialog.open(ConfigPopupComponent, {position: { top: topPosition, left: leftPosition }
    });
  }
}
}
