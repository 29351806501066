import { Component, Input } from '@angular/core';
import { VoteDecision } from 'src/app/models/enum/voteDecision.enum';

@Component({
  selector: 'app-display-decision',
  templateUrl: './display-decision.component.html',
  styleUrls: ['./display-decision.component.css']
})
export class DisplayDecisionComponent {
  @Input() voteDecision!: VoteDecision | null;
  @Input() username!: string;

 }
