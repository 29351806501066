<p class="w-full text-xs md:text-xs uppercase pt-3 md:pt-5 text-center px-6">Vote de la salle : {{room.id}}</p>
<h2 class="col-start-2 font-bold text-basic text-2xl md:text-3xl font-sans text-center uppercase mt-4 md:mt-5">Résultats</h2>
<section class="w-full flex flex-col gap-6 items-center justify-center m-4 px-6 pt-5">
  <button *ngIf="currentUser.type === 'maitreDuJeu'" tabindex="0" type="button" class="w-1/3 h-12 font-bold text-base bg-basic rounded-lg items-center justify-center disabled:opacity-50"
  (click)="reset()">Lancer un nouveau vote
  </button>
</section>
<div class="w-full max-w-screen-md px-6">
  <section *ngIf="room.roomVoteType.environnemental" class="border border-basic rounded-b-2xl w-full mt-3 md:mt-5 mb-9 md:mb-11">
    <h3 class="bg-basic text-base font-bold flex flex-row h-14 items-center">
      <svg width="31" height="26" viewBox="0 0 31 26" class="mx-6" xmlns="http://www.w3.org/2000/svg" aria-labelledby="fleur">
        <path d="M24.6763 15.8837C23.4774 16.6124 22.1005 16.9957 20.6975 16.9912C19.5222 16.9818 18.3601 16.7414 17.2775 16.2837C16.4432 17.4616 15.9966 18.8702 16 20.3137V23.9999C16.0003 24.137 15.9725 24.2727 15.9181 24.3985C15.8638 24.5244 15.7841 24.6377 15.6841 24.7315C15.5841 24.8253 15.4659 24.8975 15.3369 24.9437C15.2078 24.9899 15.0706 25.009 14.9338 24.9999C14.6768 24.9775 14.4377 24.8588 14.2645 24.6676C14.0913 24.4763 13.9969 24.2267 14 23.9687V22.4137L9.17255 17.5862C8.45492 17.8539 7.69596 17.9939 6.93005 17.9999C5.87563 18.0025 4.84097 17.714 3.94005 17.1662C1.2163 15.5112 -0.249954 11.7024 0.0337965 6.97366C0.0480833 6.72902 0.151705 6.49814 0.324989 6.32486C0.498272 6.15157 0.729153 6.04795 0.973796 6.03366C5.70255 5.75491 9.5113 7.21616 11.1613 9.93991C11.8096 11.0075 12.0926 12.2572 11.9675 13.4999C11.9598 13.5962 11.9243 13.6882 11.8653 13.7647C11.8064 13.8413 11.7266 13.8991 11.6355 13.9312C11.5444 13.9633 11.4459 13.9684 11.352 13.9458C11.2581 13.9231 11.1728 13.8738 11.1063 13.8037L8.7063 11.2912C8.51723 11.1115 8.26548 11.0129 8.00472 11.0162C7.74396 11.0196 7.49482 11.1246 7.31042 11.309C7.12602 11.4934 7.02094 11.7426 7.01761 12.0033C7.01427 12.2641 7.11293 12.5159 7.29255 12.7049L14.0275 19.6112C14.035 19.5137 14.0438 19.4162 14.0538 19.3199C14.2725 17.4658 15.0906 15.7339 16.3838 14.3874L22.7075 7.70491C22.8952 7.51744 23.0007 7.2631 23.0008 6.99786C23.0009 6.73261 22.8956 6.47818 22.7082 6.29054C22.5207 6.1029 22.2664 5.99742 22.0011 5.9973C21.7359 5.99718 21.4814 6.10244 21.2938 6.28991L15.1688 12.7674C15.1075 12.8324 15.0299 12.8797 14.9441 12.9045C14.8583 12.9293 14.7674 12.9307 14.6809 12.9085C14.5944 12.8863 14.5155 12.8413 14.4522 12.7783C14.389 12.7152 14.3438 12.6364 14.3213 12.5499C13.7288 10.3649 13.99 8.18991 15.1213 6.32241C17.3538 2.63741 22.5488 0.664914 29.0188 1.04491C29.2634 1.0592 29.4943 1.16282 29.6676 1.33611C29.8409 1.50939 29.9445 1.74027 29.9588 1.98491C30.3338 8.45616 28.3613 13.6512 24.6763 15.8837Z"/>
        </svg>
      Impact environnemental
    </h3>
    <div *ngFor="let user of room.users" class="flex flex-col gap-5 py-4">
      <app-user-vote-result [user]="user" [isVoteEnv]="true"></app-user-vote-result>
    </div>
  </section>
  <section *ngIf="room.roomVoteType.societal" class="border border-basic rounded-b-2xl w-full mt-3 md:mt-5 mb-9 md:mb-11">
    <h3 class="bg-basic text-base font-bold flex flex-row h-14 items-center">
      <svg width="24" height="30" viewBox="0 0 24 30" class="mx-6" xmlns="http://www.w3.org/2000/svg" aria-labelledby="humain">
        <path d="M8.5001 3.5C8.5001 2.80777 8.70538 2.13108 9.08996 1.55551C9.47455 0.979934 10.0212 0.53133 10.6607 0.266423C11.3003 0.00151649 12.004 -0.0677952 12.6829 0.0672531C13.3619 0.202301 13.9855 0.535644 14.475 1.02513C14.9645 1.51461 15.2978 2.13825 15.4329 2.81719C15.5679 3.49612 15.4986 4.19985 15.2337 4.83939C14.9688 5.47894 14.5202 6.02556 13.9446 6.41015C13.369 6.79473 12.6923 7 12.0001 7C11.0718 7 10.1816 6.63125 9.52523 5.97487C8.86885 5.3185 8.5001 4.42826 8.5001 3.5ZM22.9276 16.5975L17.2714 10.185C16.9429 9.81257 16.539 9.5143 16.0864 9.30998C15.6338 9.10567 15.1429 9.00001 14.6464 9H9.35386C8.85729 9.00001 8.36641 9.10567 7.91382 9.30998C7.46123 9.5143 7.0573 9.81257 6.72886 10.185L1.07261 16.5975C0.705324 16.9733 0.500692 17.4785 0.502948 18.004C0.505204 18.5295 0.714166 19.0329 1.08466 19.4056C1.45515 19.7782 1.95741 19.99 2.48285 19.9953C3.0083 20.0006 3.5147 19.7989 3.8926 19.4338L7.1251 16.8375L4.66261 26.1975C4.44836 26.6785 4.43242 27.2245 4.61823 27.7171C4.80404 28.2098 5.17662 28.6093 5.65512 28.8289C6.13362 29.0486 6.67944 29.0707 7.17414 28.8905C7.66885 28.7103 8.07254 28.3422 8.2976 27.8663L12.0001 21.5L15.6976 27.875C15.9227 28.351 16.3264 28.719 16.8211 28.8992C17.3158 29.0795 17.8616 29.0573 18.3401 28.8377C18.8186 28.618 19.1912 28.2185 19.377 27.7259C19.5628 27.2332 19.5468 26.6872 19.3326 26.2063L16.8751 16.8375L20.1126 19.4338C20.4905 19.7989 20.9969 20.0006 21.5224 19.9953C22.0478 19.99 22.5501 19.7782 22.9206 19.4056C23.291 19.0329 23.5 18.5295 23.5023 18.004C23.5045 17.4785 23.2999 16.9733 22.9326 16.5975H22.9276Z"/>
        </svg>
      Impact sociétal
    </h3>
    <div *ngFor="let user of room.users" class="flex flex-col gap-5 py-4">
      <app-user-vote-result [user]="user" [isVoteEnv]="false"></app-user-vote-result>
    </div>
  </section>
</div>




<section class="w-full max-w-screen-md py-9 md:py-7 hidden md:block">
  <app-cards-carrousel [cardsTypeToDisplay]="cardsTypeToDisplay"></app-cards-carrousel>
</section>