<p class="w-full text-xs md:text-xs uppercase pt-3 md:pt-5 text-center px-6">Vote de la salle : {{room.id}}</p>
<h1 class="w-full font-bold text-basic text-4xl md:text-3xl font-sans text-center px-6 pt-5">Vote en cours</h1>
<section *ngIf="room.roomVoteType.environnemental" class="pt-10 md:pt-7 w-full  px-6">
  <h3 class="text-xl flex flex-row text-black-400 pb-3">
    <svg width="31" height="26" viewBox="0 0 31 26" xmlns="http://www.w3.org/2000/svg"
      class="fill-black-400 mr-3 min-w-xl">
      <path
        d="M24.6762 15.8838C23.4774 16.6125 22.1005 16.9958 20.6975 16.9913C19.5221 16.982 18.3601 16.7416 17.2775 16.2838C16.4431 17.4617 15.9966 18.8703 16 20.3138V24C16.0003 24.1371 15.9724 24.2728 15.9181 24.3987C15.8637 24.5245 15.7841 24.6379 15.6841 24.7316C15.5841 24.8254 15.4659 24.8976 15.3368 24.9438C15.2077 24.99 15.0705 25.0091 14.9337 25C14.6767 24.9777 14.4376 24.8589 14.2644 24.6677C14.0913 24.4764 13.9968 24.2268 14 23.9688V22.4138L9.17249 17.5863C8.45486 17.854 7.6959 17.994 6.92999 18C5.87557 18.0026 4.84091 17.7141 3.93999 17.1663C1.21624 15.5113 -0.250015 11.7025 0.0337355 6.97379C0.0480223 6.72914 0.151644 6.49826 0.324928 6.32498C0.498211 6.1517 0.729092 6.04807 0.973735 6.03379C5.70249 5.75504 9.51124 7.21629 11.1612 9.94004C11.8095 11.0076 12.0925 12.2573 11.9675 13.5C11.9597 13.5963 11.9242 13.6883 11.8653 13.7648C11.8064 13.8414 11.7265 13.8992 11.6354 13.9313C11.5443 13.9635 11.4459 13.9685 11.3519 13.9459C11.258 13.9232 11.1727 13.8739 11.1062 13.8038L8.70624 11.2913C8.51717 11.1117 8.26542 11.013 8.00466 11.0163C7.7439 11.0197 7.49476 11.1248 7.31036 11.3092C7.12596 11.4936 7.02088 11.7427 7.01755 12.0035C7.01421 12.2642 7.11286 12.516 7.29248 12.705L14.0275 19.6113C14.035 19.5138 14.0437 19.4163 14.0537 19.32C14.2724 17.466 15.0905 15.734 16.3837 14.3875L22.7075 7.70504C22.8951 7.51756 23.0006 7.26323 23.0007 6.99798C23.0008 6.73273 22.8956 6.4783 22.7081 6.29066C22.5206 6.10302 22.2663 5.99754 22.0011 5.99742C21.7358 5.9973 21.4814 6.10256 21.2937 6.29004L15.1687 12.7675C15.1074 12.8325 15.0298 12.8798 14.944 12.9046C14.8583 12.9294 14.7674 12.9308 14.6809 12.9086C14.5944 12.8864 14.5154 12.8414 14.4521 12.7784C14.3889 12.7153 14.3437 12.6365 14.3212 12.55C13.7287 10.365 13.99 8.19004 15.1212 6.32254C17.3537 2.63754 22.5487 0.665036 29.0187 1.04504C29.2634 1.05932 29.4943 1.16295 29.6675 1.33623C29.8408 1.50951 29.9444 1.74039 29.9587 1.98504C30.3337 8.45629 28.3612 13.6513 24.6762 15.8838Z"
        fill="#343330" />
    </svg>
    Je considère que l'US a un impact environnemental :
  </h3>
  <div class="flex flex-row gap-x-2 md:gap-x-9 h-10">
    <div class="grid place-content-center h-10 w-1/4 relative">
      <input type="radio" name="voteEnv" id="positiveEnv" tabindex="0" [value]="0" [(ngModel)]="envVote"
        class="peer hidden">
      <label for="positiveEnv"
        class="grid h-full justify-items-stretch w-full bg-success absolute rounded-lg bg-opacity-30 peer-checked:bg-opacity-100 peer-checked:border hover:bg-opacity-100 hover:drop-shadow-md">
        <div class="flex flex-row gap-3  justify-self-center">
          
            <svg width="14" class="fill-text peer-checked:fill-white-200 pointer-events-none" viewBox="0 0 18 18"
              xmlns="http://www.w3.org/2000/svg" aria-labelledby="icône vote positif pour environnement">
              <path
                d="M9 0.875C7.39303 0.875 5.82214 1.35152 4.486 2.24431C3.14985 3.1371 2.10844 4.40605 1.49348 5.8907C0.87852 7.37535 0.717618 9.00901 1.03112 10.5851C1.34463 12.1612 2.11846 13.6089 3.25476 14.7452C4.39106 15.8815 5.8388 16.6554 7.41489 16.9689C8.99099 17.2824 10.6247 17.1215 12.1093 16.5065C13.594 15.8916 14.8629 14.8502 15.7557 13.514C16.6485 12.1779 17.125 10.607 17.125 9C17.1227 6.84581 16.266 4.78051 14.7427 3.25727C13.2195 1.73403 11.1542 0.877275 9 0.875ZM12.5672 7.56719L8.19219 11.9422C8.13415 12.0003 8.06522 12.0464 7.98934 12.0779C7.91347 12.1093 7.83214 12.1255 7.75 12.1255C7.66787 12.1255 7.58654 12.1093 7.51067 12.0779C7.43479 12.0464 7.36586 12.0003 7.30782 11.9422L5.43282 10.0672C5.31554 9.94991 5.24966 9.79085 5.24966 9.625C5.24966 9.45915 5.31554 9.30009 5.43282 9.18281C5.55009 9.06554 5.70915 8.99965 5.875 8.99965C6.04086 8.99965 6.19992 9.06554 6.31719 9.18281L7.75 10.6164L11.6828 6.68281C11.7409 6.62474 11.8098 6.57868 11.8857 6.54725C11.9616 6.51583 12.0429 6.49965 12.125 6.49965C12.2071 6.49965 12.2884 6.51583 12.3643 6.54725C12.4402 6.57868 12.5091 6.62474 12.5672 6.68281C12.6253 6.74088 12.6713 6.80982 12.7027 6.88569C12.7342 6.96156 12.7504 7.04288 12.7504 7.125C12.7504 7.20712 12.7342 7.28844 12.7027 7.36431C12.6713 7.44018 12.6253 7.50912 12.5672 7.56719Z" />
            </svg>
            <span class="flex text-sm items-center">
              Favorable
            </span>
        </div>
      </label>
      <label for="positive" class="sr-only">Vote "positif"</label>
    </div>
    <div class="grid place-content-center h-10 w-1/4 relative">
      <input type="radio" name="voteEnv" tabindex="0" id="negativeEnv" [value]="1" [(ngModel)]="envVote"
        class="peer hidden">
        <label for="negativeEnv" class=" grid justify-items-stretch h-full w-full peer appearance-none bg-danger absolute rounded-lg bg-opacity-30 peer-checked:bg-opacity-100 peer-checked:border hover:bg-opacity-100 hover:drop-shadow-md">
      <div class="flex flex-row gap-3 justify-self-center">
        <svg width="14" viewBox="0 0 18 18" class="fill-text peer-checked:fill-white-200 pointer-events-none"
          xmlns="http://www.w3.org/2000/svg" aria-labelledby="icône vote négatif pour environnement">
          <path
            d="M9 0.875C7.39303 0.875 5.82214 1.35152 4.486 2.24431C3.14985 3.1371 2.10844 4.40605 1.49348 5.8907C0.87852 7.37535 0.717618 9.00901 1.03112 10.5851C1.34463 12.1612 2.11846 13.6089 3.25476 14.7452C4.39106 15.8815 5.8388 16.6554 7.4149 16.9689C8.99099 17.2824 10.6247 17.1215 12.1093 16.5065C13.594 15.8916 14.8629 14.8502 15.7557 13.514C16.6485 12.1779 17.125 10.607 17.125 9C17.1227 6.84581 16.266 4.78051 14.7427 3.25727C13.2195 1.73403 11.1542 0.877275 9 0.875ZM11.9422 11.0578C12.0003 11.1159 12.0463 11.1848 12.0777 11.2607C12.1092 11.3366 12.1254 11.4179 12.1254 11.5C12.1254 11.5821 12.1092 11.6634 12.0777 11.7393C12.0463 11.8152 12.0003 11.8841 11.9422 11.9422C11.8841 12.0003 11.8152 12.0463 11.7393 12.0777C11.6634 12.1092 11.5821 12.1253 11.5 12.1253C11.4179 12.1253 11.3366 12.1092 11.2607 12.0777C11.1848 12.0463 11.1159 12.0003 11.0578 11.9422L9 9.88359L6.94219 11.9422C6.88412 12.0003 6.81518 12.0463 6.73931 12.0777C6.66344 12.1092 6.58213 12.1253 6.5 12.1253C6.41788 12.1253 6.33656 12.1092 6.26069 12.0777C6.18482 12.0463 6.11588 12.0003 6.05782 11.9422C5.99975 11.8841 5.95368 11.8152 5.92226 11.7393C5.89083 11.6634 5.87466 11.5821 5.87466 11.5C5.87466 11.4179 5.89083 11.3366 5.92226 11.2607C5.95368 11.1848 5.99975 11.1159 6.05782 11.0578L8.11641 9L6.05782 6.94219C5.94054 6.82491 5.87466 6.66585 5.87466 6.5C5.87466 6.33415 5.94054 6.17509 6.05782 6.05781C6.17509 5.94054 6.33415 5.87465 6.5 5.87465C6.66586 5.87465 6.82492 5.94054 6.94219 6.05781L9 8.11641L11.0578 6.05781C11.1159 5.99974 11.1848 5.95368 11.2607 5.92225C11.3366 5.89083 11.4179 5.87465 11.5 5.87465C11.5821 5.87465 11.6634 5.89083 11.7393 5.92225C11.8152 5.95368 11.8841 5.99974 11.9422 6.05781C12.0003 6.11588 12.0463 6.18482 12.0777 6.26069C12.1092 6.33656 12.1254 6.41788 12.1254 6.5C12.1254 6.58212 12.1092 6.66344 12.0777 6.73931C12.0463 6.81518 12.0003 6.88412 11.9422 6.94219L9.8836 9L11.9422 11.0578Z" />
        </svg>

        <span class="flex text-sm items-center">
          Défavorable
        </span>
      </div>
    </label>
    </div>
    <label for="negative" class="sr-only">Vote "négatif"</label>

    <div class="grid place-content-center h-10 w-1/4 relative">
      <input type="radio" name="voteEnv" tabindex="0" id="idkEnv" [value]="2" [(ngModel)]="envVote"
        class="hidden peer">
      <label for="idkEnv" class="grid h-full w-full peer appearance-none bg-warning bg-opacity-30  absolute rounded-lg text-text peer-checked:bg-opacity-100 peer-checked:border hover:bg-opacity-100 hover:drop-shadow-md">
        <div class="flex flex-row gap-3  justify-self-center">
          <svg width="14" viewBox="0 0 18 18" class="z-50 fill-text peer-checked:fill-white-200 pointer-events-none"
            xmlns="http://www.w3.org/2000/svg" aria-labelledby="icône vote 'je ne sais pas' pour environnement">
            <path
              d="M9 0.875C7.39303 0.875 5.82214 1.35152 4.486 2.24431C3.14985 3.1371 2.10844 4.40605 1.49348 5.8907C0.87852 7.37535 0.717618 9.00901 1.03112 10.5851C1.34463 12.1612 2.11846 13.6089 3.25476 14.7452C4.39106 15.8815 5.8388 16.6554 7.4149 16.9689C8.99099 17.2824 10.6247 17.1215 12.1093 16.5065C13.594 15.8916 14.8629 14.8502 15.7557 13.514C16.6485 12.1779 17.125 10.607 17.125 9C17.1227 6.84581 16.266 4.78051 14.7427 3.25727C13.2195 1.73403 11.1542 0.877275 9 0.875ZM9 14C8.81458 14 8.63333 13.945 8.47916 13.842C8.32499 13.739 8.20482 13.5926 8.13387 13.4213C8.06291 13.25 8.04434 13.0615 8.08052 12.8796C8.11669 12.6977 8.20598 12.5307 8.33709 12.3996C8.4682 12.2685 8.63525 12.1792 8.81711 12.143C8.99896 12.1068 9.18746 12.1254 9.35877 12.1964C9.53007 12.2673 9.67649 12.3875 9.77951 12.5417C9.88252 12.6958 9.9375 12.8771 9.9375 13.0625C9.9375 13.3111 9.83873 13.5496 9.66292 13.7254C9.4871 13.9012 9.24864 14 9 14ZM9.625 10.1938V10.25C9.625 10.4158 9.55916 10.5747 9.44195 10.6919C9.32474 10.8092 9.16576 10.875 9 10.875C8.83424 10.875 8.67527 10.8092 8.55806 10.6919C8.44085 10.5747 8.375 10.4158 8.375 10.25V9.625C8.375 9.45924 8.44085 9.30027 8.55806 9.18306C8.67527 9.06585 8.83424 9 9 9C10.0336 9 10.875 8.29688 10.875 7.4375C10.875 6.57812 10.0336 5.875 9 5.875C7.96641 5.875 7.125 6.57812 7.125 7.4375V7.75C7.125 7.91576 7.05916 8.07473 6.94195 8.19194C6.82474 8.30915 6.66576 8.375 6.5 8.375C6.33424 8.375 6.17527 8.30915 6.05806 8.19194C5.94085 8.07473 5.875 7.91576 5.875 7.75V7.4375C5.875 5.88672 7.27657 4.625 9 4.625C10.7234 4.625 12.125 5.88672 12.125 7.4375C12.125 8.79531 11.05 9.93203 9.625 10.1938Z" />
          </svg>
  
          <span class="flex text-sm items-center">
            Manque d'information
          </span>
        </div>
      </label>
        
    </div>
    <label for="idk" class="sr-only">Vote "Je ne sais pas"</label>
    <div class="grid place-content-center h-10 w-1/4 relative">
      <input type="radio" tabindex="0" name="voteEnv" id="neutralEnv" [value]="3" [(ngModel)]="envVote"
        class="hidden peer">
        <label for="neutralEnv" class="grid justify-items-stretch h-full w-full peer appearance-none bg-info bg-opacity-30 absolute rounded-lg peer-checked:bg-opacity-100 peer-checked:border hover:bg-opacity-100 hover:drop-shadow-md">
          <div class="flex flex-row gap-3 justify-self-center">
            <svg width="14" viewBox="0 0 16 16" class="z-50 fill-text peer-checked:fill-white-200 pointer-events-none"
              xmlns="http://www.w3.org/2000/svg" aria-labelledby="icône vote neutre pour environnement">
              <path
                d="M14.25 0.5H1.75C1.41848 0.5 1.10054 0.631696 0.866116 0.866116C0.631696 1.10054 0.5 1.41848 0.5 1.75V14.25C0.5 14.5815 0.631696 14.8995 0.866116 15.1339C1.10054 15.3683 1.41848 15.5 1.75 15.5H14.25C14.5815 15.5 14.8995 15.3683 15.1339 15.1339C15.3683 14.8995 15.5 14.5815 15.5 14.25V1.75C15.5 1.41848 15.3683 1.10054 15.1339 0.866116C14.8995 0.631696 14.5815 0.5 14.25 0.5ZM12.375 10.5H3.625C3.45924 10.5 3.30027 10.4342 3.18306 10.3169C3.06585 10.1997 3 10.0408 3 9.875C3 9.70924 3.06585 9.55027 3.18306 9.43306C3.30027 9.31585 3.45924 9.25 3.625 9.25H12.375C12.5408 9.25 12.6997 9.31585 12.8169 9.43306C12.9342 9.55027 13 9.70924 13 9.875C13 10.0408 12.9342 10.1997 12.8169 10.3169C12.6997 10.4342 12.5408 10.5 12.375 10.5ZM12.375 6.75H3.625C3.45924 6.75 3.30027 6.68415 3.18306 6.56694C3.06585 6.44973 3 6.29076 3 6.125C3 5.95924 3.06585 5.80027 3.18306 5.68306C3.30027 5.56585 3.45924 5.5 3.625 5.5H12.375C12.5408 5.5 12.6997 5.56585 12.8169 5.68306C12.9342 5.80027 13 5.95924 13 6.125C13 6.29076 12.9342 6.44973 12.8169 6.56694C12.6997 6.68415 12.5408 6.75 12.375 6.75Z" />
            </svg>
    
            <div class="flex text-sm items-center">
              Neutre
            </div>
          </div>
        </label>
        
    </div>
    <label for="neutral" class="sr-only">Vote "neutre"</label>
  </div>
</section>
<section *ngIf="room.roomVoteType.societal" class="pt-10 md:pt-7 w-full px-6">
  <h3 for="voteEnv" class="text-xl flex flex-row text-black-400 pb-3">
    <svg width="24" height="30" viewBox="0 0 24 30" class="min-w- fill-black-400 mr-4"
      xmlns="http://www.w3.org/2000/svg">
      <path
        d="M8.5001 3.5C8.5001 2.80777 8.70538 2.13108 9.08996 1.55551C9.47455 0.979934 10.0212 0.53133 10.6607 0.266423C11.3003 0.00151649 12.004 -0.0677952 12.6829 0.0672531C13.3619 0.202301 13.9855 0.535644 14.475 1.02513C14.9645 1.51461 15.2978 2.13825 15.4329 2.81719C15.5679 3.49612 15.4986 4.19985 15.2337 4.83939C14.9688 5.47894 14.5202 6.02556 13.9446 6.41015C13.369 6.79473 12.6923 7 12.0001 7C11.0718 7 10.1816 6.63125 9.52523 5.97487C8.86885 5.3185 8.5001 4.42826 8.5001 3.5ZM22.9276 16.5975L17.2714 10.185C16.9429 9.81257 16.539 9.5143 16.0864 9.30998C15.6338 9.10567 15.1429 9.00001 14.6464 9H9.35386C8.85729 9.00001 8.36641 9.10567 7.91382 9.30998C7.46123 9.5143 7.0573 9.81257 6.72886 10.185L1.07261 16.5975C0.705324 16.9733 0.500692 17.4785 0.502948 18.004C0.505204 18.5295 0.714166 19.0329 1.08466 19.4056C1.45515 19.7782 1.95741 19.99 2.48285 19.9953C3.0083 20.0006 3.5147 19.7989 3.8926 19.4338L7.1251 16.8375L4.66261 26.1975C4.44836 26.6785 4.43242 27.2245 4.61823 27.7171C4.80404 28.2098 5.17662 28.6093 5.65512 28.8289C6.13362 29.0486 6.67944 29.0707 7.17414 28.8905C7.66885 28.7103 8.07254 28.3422 8.2976 27.8663L12.0001 21.5L15.6976 27.875C15.9227 28.351 16.3264 28.719 16.8211 28.8992C17.3158 29.0795 17.8616 29.0573 18.3401 28.8377C18.8186 28.618 19.1912 28.2185 19.377 27.7259C19.5628 27.2332 19.5468 26.6872 19.3326 26.2063L16.8751 16.8375L20.1126 19.4338C20.4905 19.7989 20.9969 20.0006 21.5224 19.9953C22.0478 19.99 22.5501 19.7782 22.9206 19.4056C23.291 19.0329 23.5 18.5295 23.5023 18.004C23.5045 17.4785 23.2999 16.9733 22.9326 16.5975H22.9276Z" />
    </svg>
    <p>Je considère que l'US a un impact sociétal :</p>
  </h3>
  <form>
    <div class="flex flex-row gap-x-2 md:gap-x-9 h-10">
    
      <div class="grid place-content-center h-10 w-1/4 relative">
        <input type="radio" name="socVote" id="positiveSoc" tabindex="0" [value]="0" [(ngModel)]="socVote"
          class="peer hidden">
        <label for="positiveSoc"
          class="grid h-full justify-items-stretch w-full bg-success absolute rounded-lg bg-opacity-30 peer-checked:bg-opacity-100 peer-checked:border hover:bg-opacity-100 hover:drop-shadow-md">
          <div class="flex flex-row gap-3  justify-self-center">
            
              <svg width="14" class="fill-text peer-checked:fill-white-200 pointer-events-none" viewBox="0 0 18 18"
                xmlns="http://www.w3.org/2000/svg" aria-labelledby="icône vote positif pour environnement">
                <path
                  d="M9 0.875C7.39303 0.875 5.82214 1.35152 4.486 2.24431C3.14985 3.1371 2.10844 4.40605 1.49348 5.8907C0.87852 7.37535 0.717618 9.00901 1.03112 10.5851C1.34463 12.1612 2.11846 13.6089 3.25476 14.7452C4.39106 15.8815 5.8388 16.6554 7.41489 16.9689C8.99099 17.2824 10.6247 17.1215 12.1093 16.5065C13.594 15.8916 14.8629 14.8502 15.7557 13.514C16.6485 12.1779 17.125 10.607 17.125 9C17.1227 6.84581 16.266 4.78051 14.7427 3.25727C13.2195 1.73403 11.1542 0.877275 9 0.875ZM12.5672 7.56719L8.19219 11.9422C8.13415 12.0003 8.06522 12.0464 7.98934 12.0779C7.91347 12.1093 7.83214 12.1255 7.75 12.1255C7.66787 12.1255 7.58654 12.1093 7.51067 12.0779C7.43479 12.0464 7.36586 12.0003 7.30782 11.9422L5.43282 10.0672C5.31554 9.94991 5.24966 9.79085 5.24966 9.625C5.24966 9.45915 5.31554 9.30009 5.43282 9.18281C5.55009 9.06554 5.70915 8.99965 5.875 8.99965C6.04086 8.99965 6.19992 9.06554 6.31719 9.18281L7.75 10.6164L11.6828 6.68281C11.7409 6.62474 11.8098 6.57868 11.8857 6.54725C11.9616 6.51583 12.0429 6.49965 12.125 6.49965C12.2071 6.49965 12.2884 6.51583 12.3643 6.54725C12.4402 6.57868 12.5091 6.62474 12.5672 6.68281C12.6253 6.74088 12.6713 6.80982 12.7027 6.88569C12.7342 6.96156 12.7504 7.04288 12.7504 7.125C12.7504 7.20712 12.7342 7.28844 12.7027 7.36431C12.6713 7.44018 12.6253 7.50912 12.5672 7.56719Z" />
              </svg>
              <span class="flex text-sm items-center">
                Favorable
              </span>
          </div>
        </label>
        <label for="positive" class="sr-only">Vote "positif"</label>
      </div>
      <div class="grid place-content-center h-10 w-1/4 relative">
        <input type="radio" name="socVote" tabindex="0" id="negativeSoc" [value]="1" [(ngModel)]="socVote"
          class="peer hidden">
          <label for="negativeSoc" class=" grid justify-items-stretch h-full w-full peer appearance-none bg-danger absolute rounded-lg bg-opacity-30 peer-checked:bg-opacity-100 peer-checked:border hover:bg-opacity-100 hover:drop-shadow-md">
        <div class="flex flex-row gap-3 justify-self-center">
          <svg width="14" viewBox="0 0 18 18" class="fill-text peer-checked:fill-white-200 pointer-events-none"
            xmlns="http://www.w3.org/2000/svg" aria-labelledby="icône vote négatif pour environnement">
            <path
              d="M9 0.875C7.39303 0.875 5.82214 1.35152 4.486 2.24431C3.14985 3.1371 2.10844 4.40605 1.49348 5.8907C0.87852 7.37535 0.717618 9.00901 1.03112 10.5851C1.34463 12.1612 2.11846 13.6089 3.25476 14.7452C4.39106 15.8815 5.8388 16.6554 7.4149 16.9689C8.99099 17.2824 10.6247 17.1215 12.1093 16.5065C13.594 15.8916 14.8629 14.8502 15.7557 13.514C16.6485 12.1779 17.125 10.607 17.125 9C17.1227 6.84581 16.266 4.78051 14.7427 3.25727C13.2195 1.73403 11.1542 0.877275 9 0.875ZM11.9422 11.0578C12.0003 11.1159 12.0463 11.1848 12.0777 11.2607C12.1092 11.3366 12.1254 11.4179 12.1254 11.5C12.1254 11.5821 12.1092 11.6634 12.0777 11.7393C12.0463 11.8152 12.0003 11.8841 11.9422 11.9422C11.8841 12.0003 11.8152 12.0463 11.7393 12.0777C11.6634 12.1092 11.5821 12.1253 11.5 12.1253C11.4179 12.1253 11.3366 12.1092 11.2607 12.0777C11.1848 12.0463 11.1159 12.0003 11.0578 11.9422L9 9.88359L6.94219 11.9422C6.88412 12.0003 6.81518 12.0463 6.73931 12.0777C6.66344 12.1092 6.58213 12.1253 6.5 12.1253C6.41788 12.1253 6.33656 12.1092 6.26069 12.0777C6.18482 12.0463 6.11588 12.0003 6.05782 11.9422C5.99975 11.8841 5.95368 11.8152 5.92226 11.7393C5.89083 11.6634 5.87466 11.5821 5.87466 11.5C5.87466 11.4179 5.89083 11.3366 5.92226 11.2607C5.95368 11.1848 5.99975 11.1159 6.05782 11.0578L8.11641 9L6.05782 6.94219C5.94054 6.82491 5.87466 6.66585 5.87466 6.5C5.87466 6.33415 5.94054 6.17509 6.05782 6.05781C6.17509 5.94054 6.33415 5.87465 6.5 5.87465C6.66586 5.87465 6.82492 5.94054 6.94219 6.05781L9 8.11641L11.0578 6.05781C11.1159 5.99974 11.1848 5.95368 11.2607 5.92225C11.3366 5.89083 11.4179 5.87465 11.5 5.87465C11.5821 5.87465 11.6634 5.89083 11.7393 5.92225C11.8152 5.95368 11.8841 5.99974 11.9422 6.05781C12.0003 6.11588 12.0463 6.18482 12.0777 6.26069C12.1092 6.33656 12.1254 6.41788 12.1254 6.5C12.1254 6.58212 12.1092 6.66344 12.0777 6.73931C12.0463 6.81518 12.0003 6.88412 11.9422 6.94219L9.8836 9L11.9422 11.0578Z" />
          </svg>
  
          <span class="flex text-sm items-center">
            Défavorable
          </span>
        </div>
      </label>
      </div>
      <label for="negative" class="sr-only">Vote "négatif"</label>
  
      <div class="grid place-content-center h-10 w-1/4 relative">
        <input type="radio" name="socVote" tabindex="0" id="idkSoc" [value]="2" [(ngModel)]="socVote"
          class="hidden peer">
        <label for="idkSoc" class="grid h-full w-full peer appearance-none bg-warning bg-opacity-30  absolute rounded-lg text-text peer-checked:bg-opacity-100 peer-checked:border hover:bg-opacity-100 hover:drop-shadow-md">
          <div class="flex flex-row gap-3  justify-self-center">
            <svg width="14" viewBox="0 0 18 18" class="z-50 fill-text peer-checked:fill-white-200 pointer-events-none"
              xmlns="http://www.w3.org/2000/svg" aria-labelledby="icône vote 'je ne sais pas' pour environnement">
              <path
                d="M9 0.875C7.39303 0.875 5.82214 1.35152 4.486 2.24431C3.14985 3.1371 2.10844 4.40605 1.49348 5.8907C0.87852 7.37535 0.717618 9.00901 1.03112 10.5851C1.34463 12.1612 2.11846 13.6089 3.25476 14.7452C4.39106 15.8815 5.8388 16.6554 7.4149 16.9689C8.99099 17.2824 10.6247 17.1215 12.1093 16.5065C13.594 15.8916 14.8629 14.8502 15.7557 13.514C16.6485 12.1779 17.125 10.607 17.125 9C17.1227 6.84581 16.266 4.78051 14.7427 3.25727C13.2195 1.73403 11.1542 0.877275 9 0.875ZM9 14C8.81458 14 8.63333 13.945 8.47916 13.842C8.32499 13.739 8.20482 13.5926 8.13387 13.4213C8.06291 13.25 8.04434 13.0615 8.08052 12.8796C8.11669 12.6977 8.20598 12.5307 8.33709 12.3996C8.4682 12.2685 8.63525 12.1792 8.81711 12.143C8.99896 12.1068 9.18746 12.1254 9.35877 12.1964C9.53007 12.2673 9.67649 12.3875 9.77951 12.5417C9.88252 12.6958 9.9375 12.8771 9.9375 13.0625C9.9375 13.3111 9.83873 13.5496 9.66292 13.7254C9.4871 13.9012 9.24864 14 9 14ZM9.625 10.1938V10.25C9.625 10.4158 9.55916 10.5747 9.44195 10.6919C9.32474 10.8092 9.16576 10.875 9 10.875C8.83424 10.875 8.67527 10.8092 8.55806 10.6919C8.44085 10.5747 8.375 10.4158 8.375 10.25V9.625C8.375 9.45924 8.44085 9.30027 8.55806 9.18306C8.67527 9.06585 8.83424 9 9 9C10.0336 9 10.875 8.29688 10.875 7.4375C10.875 6.57812 10.0336 5.875 9 5.875C7.96641 5.875 7.125 6.57812 7.125 7.4375V7.75C7.125 7.91576 7.05916 8.07473 6.94195 8.19194C6.82474 8.30915 6.66576 8.375 6.5 8.375C6.33424 8.375 6.17527 8.30915 6.05806 8.19194C5.94085 8.07473 5.875 7.91576 5.875 7.75V7.4375C5.875 5.88672 7.27657 4.625 9 4.625C10.7234 4.625 12.125 5.88672 12.125 7.4375C12.125 8.79531 11.05 9.93203 9.625 10.1938Z" />
            </svg>
    
            <span class="flex text-sm items-center">
              Manque d'information
            </span>
          </div>
        </label>
          
      </div>
      <label for="idk" class="sr-only">Vote "Je ne sais pas"</label>
      <div class="grid place-content-center h-10 w-1/4 relative">
        <input type="radio" tabindex="0" name="socVote" id="neutralSoc" [value]="3" [(ngModel)]="socVote"
          class="hidden peer">
          <label for="neutralSoc" class="grid justify-items-stretch h-full w-full peer appearance-none bg-info bg-opacity-30 absolute rounded-lg peer-checked:bg-opacity-100 peer-checked:border hover:bg-opacity-100 hover:drop-shadow-md">
            <div class="flex flex-row gap-3 justify-self-center">
              <svg width="14" viewBox="0 0 16 16" class="z-50 fill-text peer-checked:fill-white-200 pointer-events-none"
                xmlns="http://www.w3.org/2000/svg" aria-labelledby="icône vote neutre pour environnement">
                <path
                  d="M14.25 0.5H1.75C1.41848 0.5 1.10054 0.631696 0.866116 0.866116C0.631696 1.10054 0.5 1.41848 0.5 1.75V14.25C0.5 14.5815 0.631696 14.8995 0.866116 15.1339C1.10054 15.3683 1.41848 15.5 1.75 15.5H14.25C14.5815 15.5 14.8995 15.3683 15.1339 15.1339C15.3683 14.8995 15.5 14.5815 15.5 14.25V1.75C15.5 1.41848 15.3683 1.10054 15.1339 0.866116C14.8995 0.631696 14.5815 0.5 14.25 0.5ZM12.375 10.5H3.625C3.45924 10.5 3.30027 10.4342 3.18306 10.3169C3.06585 10.1997 3 10.0408 3 9.875C3 9.70924 3.06585 9.55027 3.18306 9.43306C3.30027 9.31585 3.45924 9.25 3.625 9.25H12.375C12.5408 9.25 12.6997 9.31585 12.8169 9.43306C12.9342 9.55027 13 9.70924 13 9.875C13 10.0408 12.9342 10.1997 12.8169 10.3169C12.6997 10.4342 12.5408 10.5 12.375 10.5ZM12.375 6.75H3.625C3.45924 6.75 3.30027 6.68415 3.18306 6.56694C3.06585 6.44973 3 6.29076 3 6.125C3 5.95924 3.06585 5.80027 3.18306 5.68306C3.30027 5.56585 3.45924 5.5 3.625 5.5H12.375C12.5408 5.5 12.6997 5.56585 12.8169 5.68306C12.9342 5.80027 13 5.95924 13 6.125C13 6.29076 12.9342 6.44973 12.8169 6.56694C12.6997 6.68415 12.5408 6.75 12.375 6.75Z" />
              </svg>
      
              <div class="flex text-sm items-center">
                Neutre
              </div>
            </div>
          </label>
          
      </div>
      <label for="neutral" class="sr-only">Vote "neutre"</label>
    </div>
  </form>
  
  <!-- <div class="flex flex-row gap-x-2 md:gap-x-9 h-10">
    <div class="grid place-content-center h-10 w-1/4 relative">
      <input type="radio" name="socVote" id="positive" tabindex="0" [value]="0" [(ngModel)]="socVote"
        class="z-0 h-full w-full peer appearance-none bg-success absolute rounded-lg bg-opacity-30 checked:bg-opacity-100 checked:border hover:bg-opacity-100 hover:drop-shadow-md">
      <div class="flex flex-row gap-1">
        <svg width="14" class="z-50 fill-text peer-checked:fill-white-200 pointer-events-none" viewBox="0 0 18 18"
          xmlns="http://www.w3.org/2000/svg" aria-labelledby="icône vote positif pour sociétal">
          <path
            d="M9 0.875C7.39303 0.875 5.82214 1.35152 4.486 2.24431C3.14985 3.1371 2.10844 4.40605 1.49348 5.8907C0.87852 7.37535 0.717618 9.00901 1.03112 10.5851C1.34463 12.1612 2.11846 13.6089 3.25476 14.7452C4.39106 15.8815 5.8388 16.6554 7.41489 16.9689C8.99099 17.2824 10.6247 17.1215 12.1093 16.5065C13.594 15.8916 14.8629 14.8502 15.7557 13.514C16.6485 12.1779 17.125 10.607 17.125 9C17.1227 6.84581 16.266 4.78051 14.7427 3.25727C13.2195 1.73403 11.1542 0.877275 9 0.875ZM12.5672 7.56719L8.19219 11.9422C8.13415 12.0003 8.06522 12.0464 7.98934 12.0779C7.91347 12.1093 7.83214 12.1255 7.75 12.1255C7.66787 12.1255 7.58654 12.1093 7.51067 12.0779C7.43479 12.0464 7.36586 12.0003 7.30782 11.9422L5.43282 10.0672C5.31554 9.94991 5.24966 9.79085 5.24966 9.625C5.24966 9.45915 5.31554 9.30009 5.43282 9.18281C5.55009 9.06554 5.70915 8.99965 5.875 8.99965C6.04086 8.99965 6.19992 9.06554 6.31719 9.18281L7.75 10.6164L11.6828 6.68281C11.7409 6.62474 11.8098 6.57868 11.8857 6.54725C11.9616 6.51583 12.0429 6.49965 12.125 6.49965C12.2071 6.49965 12.2884 6.51583 12.3643 6.54725C12.4402 6.57868 12.5091 6.62474 12.5672 6.68281C12.6253 6.74088 12.6713 6.80982 12.7027 6.88569C12.7342 6.96156 12.7504 7.04288 12.7504 7.125C12.7504 7.20712 12.7342 7.28844 12.7027 7.36431C12.6713 7.44018 12.6253 7.50912 12.5672 7.56719Z" />
        </svg>
        <div class="text-sm z-50">
          Favorable
        </div>
      </div>
      <label for="positive" class="sr-only">Vote "positif"</label>
    </div>
    <div class="grid place-content-center h-10 w-1/4 relative">
      <input type="radio" name="socVote" tabindex="0" id="negative" [value]="1" [(ngModel)]="socVote"
        class="z-0 h-full w-full peer appearance-none bg-danger absolute rounded-lg bg-opacity-30 checked:bg-opacity-100 checked:border hover:bg-opacity-100 hover:drop-shadow-md">
      <div class="flex flex-row gap-1">

        <svg width="14" viewBox="0 0 18 18" class="z-50 fill-text peer-checked:fill-white-200 pointer-events-none"
          xmlns="http://www.w3.org/2000/svg" aria-labelledby="icône vote négatif pour sociétal">
          <path
            d="M9 0.875C7.39303 0.875 5.82214 1.35152 4.486 2.24431C3.14985 3.1371 2.10844 4.40605 1.49348 5.8907C0.87852 7.37535 0.717618 9.00901 1.03112 10.5851C1.34463 12.1612 2.11846 13.6089 3.25476 14.7452C4.39106 15.8815 5.8388 16.6554 7.4149 16.9689C8.99099 17.2824 10.6247 17.1215 12.1093 16.5065C13.594 15.8916 14.8629 14.8502 15.7557 13.514C16.6485 12.1779 17.125 10.607 17.125 9C17.1227 6.84581 16.266 4.78051 14.7427 3.25727C13.2195 1.73403 11.1542 0.877275 9 0.875ZM11.9422 11.0578C12.0003 11.1159 12.0463 11.1848 12.0777 11.2607C12.1092 11.3366 12.1254 11.4179 12.1254 11.5C12.1254 11.5821 12.1092 11.6634 12.0777 11.7393C12.0463 11.8152 12.0003 11.8841 11.9422 11.9422C11.8841 12.0003 11.8152 12.0463 11.7393 12.0777C11.6634 12.1092 11.5821 12.1253 11.5 12.1253C11.4179 12.1253 11.3366 12.1092 11.2607 12.0777C11.1848 12.0463 11.1159 12.0003 11.0578 11.9422L9 9.88359L6.94219 11.9422C6.88412 12.0003 6.81518 12.0463 6.73931 12.0777C6.66344 12.1092 6.58213 12.1253 6.5 12.1253C6.41788 12.1253 6.33656 12.1092 6.26069 12.0777C6.18482 12.0463 6.11588 12.0003 6.05782 11.9422C5.99975 11.8841 5.95368 11.8152 5.92226 11.7393C5.89083 11.6634 5.87466 11.5821 5.87466 11.5C5.87466 11.4179 5.89083 11.3366 5.92226 11.2607C5.95368 11.1848 5.99975 11.1159 6.05782 11.0578L8.11641 9L6.05782 6.94219C5.94054 6.82491 5.87466 6.66585 5.87466 6.5C5.87466 6.33415 5.94054 6.17509 6.05782 6.05781C6.17509 5.94054 6.33415 5.87465 6.5 5.87465C6.66586 5.87465 6.82492 5.94054 6.94219 6.05781L9 8.11641L11.0578 6.05781C11.1159 5.99974 11.1848 5.95368 11.2607 5.92225C11.3366 5.89083 11.4179 5.87465 11.5 5.87465C11.5821 5.87465 11.6634 5.89083 11.7393 5.92225C11.8152 5.95368 11.8841 5.99974 11.9422 6.05781C12.0003 6.11588 12.0463 6.18482 12.0777 6.26069C12.1092 6.33656 12.1254 6.41788 12.1254 6.5C12.1254 6.58212 12.1092 6.66344 12.0777 6.73931C12.0463 6.81518 12.0003 6.88412 11.9422 6.94219L9.8836 9L11.9422 11.0578Z" />
        </svg>
        <div class="text-sm z-50">
          Défavorable
        </div>
      </div>
    </div>
    <label for="negative" class="sr-only">Vote "négatif"</label>
    <div class="grid place-content-center h-10 w-1/4 relative">
      <input type="radio" name="socVote" tabindex="0" id="idk" [value]="2" [(ngModel)]="socVote"
        class="z-0 h-full w-full peer appearance-none bg-warning absolute rounded-lg bg-opacity-30 checked:bg-opacity-100 checked:border hover:bg-opacity-100 hover:drop-shadow-md">
      <div class="flex flex-row gap-1">

        <svg width="14" viewBox="0 0 18 18" class="z-50 fill-text peer-checked:fill-white-200 pointer-events-none"
          xmlns="http://www.w3.org/2000/svg" aria-labelledby="icône vote 'je ne sais pas' pour sociétal">
          <path
            d="M9 0.875C7.39303 0.875 5.82214 1.35152 4.486 2.24431C3.14985 3.1371 2.10844 4.40605 1.49348 5.8907C0.87852 7.37535 0.717618 9.00901 1.03112 10.5851C1.34463 12.1612 2.11846 13.6089 3.25476 14.7452C4.39106 15.8815 5.8388 16.6554 7.4149 16.9689C8.99099 17.2824 10.6247 17.1215 12.1093 16.5065C13.594 15.8916 14.8629 14.8502 15.7557 13.514C16.6485 12.1779 17.125 10.607 17.125 9C17.1227 6.84581 16.266 4.78051 14.7427 3.25727C13.2195 1.73403 11.1542 0.877275 9 0.875ZM9 14C8.81458 14 8.63333 13.945 8.47916 13.842C8.32499 13.739 8.20482 13.5926 8.13387 13.4213C8.06291 13.25 8.04434 13.0615 8.08052 12.8796C8.11669 12.6977 8.20598 12.5307 8.33709 12.3996C8.4682 12.2685 8.63525 12.1792 8.81711 12.143C8.99896 12.1068 9.18746 12.1254 9.35877 12.1964C9.53007 12.2673 9.67649 12.3875 9.77951 12.5417C9.88252 12.6958 9.9375 12.8771 9.9375 13.0625C9.9375 13.3111 9.83873 13.5496 9.66292 13.7254C9.4871 13.9012 9.24864 14 9 14ZM9.625 10.1938V10.25C9.625 10.4158 9.55916 10.5747 9.44195 10.6919C9.32474 10.8092 9.16576 10.875 9 10.875C8.83424 10.875 8.67527 10.8092 8.55806 10.6919C8.44085 10.5747 8.375 10.4158 8.375 10.25V9.625C8.375 9.45924 8.44085 9.30027 8.55806 9.18306C8.67527 9.06585 8.83424 9 9 9C10.0336 9 10.875 8.29688 10.875 7.4375C10.875 6.57812 10.0336 5.875 9 5.875C7.96641 5.875 7.125 6.57812 7.125 7.4375V7.75C7.125 7.91576 7.05916 8.07473 6.94195 8.19194C6.82474 8.30915 6.66576 8.375 6.5 8.375C6.33424 8.375 6.17527 8.30915 6.05806 8.19194C5.94085 8.07473 5.875 7.91576 5.875 7.75V7.4375C5.875 5.88672 7.27657 4.625 9 4.625C10.7234 4.625 12.125 5.88672 12.125 7.4375C12.125 8.79531 11.05 9.93203 9.625 10.1938Z" />
        </svg>
        <div class="text-sm z-50">
          Manque d'information
        </div>
      </div>
    </div>
    <label for="idk" class="sr-only">Vote "Je ne sais pas"</label>
    <div class="grid place-content-center h-10 w-1/4 relative">
      <input type="radio" tabindex="0" name="socVote" id="neutral" [value]="3" [(ngModel)]="socVote"
        class="z-0 h-full w-full peer appearance-none bg-info absolute rounded-lg bg-opacity-30 checked:bg-opacity-100 checked:border hover:bg-opacity-100 hover:drop-shadow-md">
      <div class="flex flex-row gap-1">

        <svg width="14" viewBox="0 0 16 16" class="z-50 fill-text peer-checked:fill-white-200 pointer-events-none"
          xmlns="http://www.w3.org/2000/svg" aria-labelledby="icône vote neutre pour sociétal">
          <path
            d="M14.25 0.5H1.75C1.41848 0.5 1.10054 0.631696 0.866116 0.866116C0.631696 1.10054 0.5 1.41848 0.5 1.75V14.25C0.5 14.5815 0.631696 14.8995 0.866116 15.1339C1.10054 15.3683 1.41848 15.5 1.75 15.5H14.25C14.5815 15.5 14.8995 15.3683 15.1339 15.1339C15.3683 14.8995 15.5 14.5815 15.5 14.25V1.75C15.5 1.41848 15.3683 1.10054 15.1339 0.866116C14.8995 0.631696 14.5815 0.5 14.25 0.5ZM12.375 10.5H3.625C3.45924 10.5 3.30027 10.4342 3.18306 10.3169C3.06585 10.1997 3 10.0408 3 9.875C3 9.70924 3.06585 9.55027 3.18306 9.43306C3.30027 9.31585 3.45924 9.25 3.625 9.25H12.375C12.5408 9.25 12.6997 9.31585 12.8169 9.43306C12.9342 9.55027 13 9.70924 13 9.875C13 10.0408 12.9342 10.1997 12.8169 10.3169C12.6997 10.4342 12.5408 10.5 12.375 10.5ZM12.375 6.75H3.625C3.45924 6.75 3.30027 6.68415 3.18306 6.56694C3.06585 6.44973 3 6.29076 3 6.125C3 5.95924 3.06585 5.80027 3.18306 5.68306C3.30027 5.56585 3.45924 5.5 3.625 5.5H12.375C12.5408 5.5 12.6997 5.56585 12.8169 5.68306C12.9342 5.80027 13 5.95924 13 6.125C13 6.29076 12.9342 6.44973 12.8169 6.56694C12.6997 6.68415 12.5408 6.75 12.375 6.75Z" />
        </svg>
        <div class="text-sm z-50">
          Neutre
        </div>
      </div>
    </div>
    <label for="neutral" class="sr-only">Vote "neutre"</label>
  </div> -->
</section>
<section class="w-full flex flex-col gap-6 items-center justify-center mt-auto px-6 pt-5">
  <button tabindex="0" type="button"
    class="w-1/2 h-10 font-bold text-base bg-basic rounded-lg items-center justify-center disabled:opacity-50"
    (click)="validVote()" [disabled]="!isVoteValid">Valider le vote
  </button>
</section>
<section class="w-fullpy-9 md:py-7 grow">
  <app-cards-carrousel [cardsTypeToDisplay]="cardsTypeToDisplay"></app-cards-carrousel>
</section>