import { Component, EventEmitter, Input, Output } from '@angular/core';
import { CardsType } from 'src/app/models/enum/cardstype.enum';
import { RoomStatus } from 'src/app/models/enum/roomStatus.enum';
import { UserRole } from 'src/app/models/enum/userRole.enum';
import { VoteDecision } from 'src/app/models/enum/voteDecision.enum';
import { Room } from 'src/app/models/room.interface';
import { User } from 'src/app/models/user.interface';

@Component({
  selector: 'app-voting-section',
  templateUrl: './voting-section.component.html',
  styleUrls: ['./voting-section.component.css']
})
export class VotingSectionComponent {
  @Input() room: Room = {id: '', status: RoomStatus.pending, users: [], roomVoteType: {environnemental: false, societal: false}};
  @Input() currentUser: User = {username: '', type: UserRole.player, envVote: null, socVote: null};
  @Output() voteDecisionEvent = new EventEmitter<{envVote: VoteDecision | null, socVote: VoteDecision | null}>();
  envVote: VoteDecision | null = null;
  socVote: VoteDecision | null = null;

  
  validVote() {
    this.voteDecisionEvent.emit({ envVote: this.envVote, socVote: this.socVote});
  }

  get isVoteValid(): boolean {
    if (this.room.roomVoteType.environnemental && this.room.roomVoteType.societal) {
      return this.envVote !== null && this.socVote !== null;
    }
    return this.envVote !== null || this.socVote !== null;
  }

  get cardsTypeToDisplay(){
    if(this.room.roomVoteType.environnemental && this.room.roomVoteType.societal)
    {
      return null
    }
    return this.room.roomVoteType.environnemental ? CardsType.environnementale : CardsType.societale;
  }
}
