import { Component, EventEmitter, Input, Output } from '@angular/core';
import { CardsType } from 'src/app/models/enum/cardstype.enum';
import { RoomStatus } from 'src/app/models/enum/roomStatus.enum';
import { UserRole } from 'src/app/models/enum/userRole.enum';
import { Room } from 'src/app/models/room.interface';
import { User } from 'src/app/models/user.interface';

@Component({
  selector: 'app-has-voted-section',
  templateUrl: './has-voted-section.component.html',
  styleUrls: ['./has-voted-section.component.css']
})
export class HasVotedSectionComponent {
  @Input() room: Room = {id: '', status: RoomStatus.pending, users: [], roomVoteType: {environnemental: false, societal: false}};
  @Input() currentUser: User = {username: '', type: UserRole.player, envVote: null, socVote: null};
  @Output() revealVoteEvent = new EventEmitter();
  showHelp: boolean = false;

  revealVote() {
    this.revealVoteEvent.emit();
  }

  get haveVotedUsersNumber() {
    return this.room.users.filter(user => user.envVote !== null || user.socVote !== null).length;
  }

  get currentUserSocVote(){
    return this.currentUser.socVote;
  }

  get currentUserEnvVote(){
    return this.currentUser.envVote;
  }

  get cardsTypeToDisplay(){
    if(this.room.roomVoteType.environnemental && this.room.roomVoteType.societal)
    {
      return null
    }
    return this.room.roomVoteType.environnemental ? CardsType.environnementale : CardsType.societale;
  }

  get usersNumber() {
    return this.room.users.length;
  }

  get isRoomVoteTypeEnv(){
    return this.room.roomVoteType.environnemental
  }

  get isRoomVoteTypeSoc(){
    return this.room.roomVoteType.societal
  }

  showHelpCards() {
    this.showHelp = !this.showHelp;
  }
}
