import { Component, Input } from '@angular/core';
import { User } from 'src/app/models/user.interface';

@Component({
  selector: 'app-user-vote-result',
  templateUrl: './user-vote-result.component.html',
  styleUrls: ['./user-vote-result.component.css']
})
export class UserVoteResultComponent {
  @Input() user!: User;
  @Input() isVoteEnv: boolean = true;
}
