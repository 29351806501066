import { Component, Input } from '@angular/core';
import { Card } from 'src/app/models/card.interface';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-card',
  templateUrl: './card.component.html',
  styleUrls: ['./card.component.css']
})
export class CardComponent {
  @Input() card!: Card;

  displayRecto : boolean = true;

  flipCard(){
    this.displayRecto = !this.displayRecto;
  }

  get imageUrl() {
    return this.displayRecto ? this.rectoImageUrl : this.versoImageUrl
  }

  get rectoImageUrl(){
    return environment.apiUrl + this.card.images.recto;
  }

  get versoImageUrl(){
    return environment.apiUrl + this.card.images.verso;
  }
}
