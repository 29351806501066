import { Component } from '@angular/core';
import { Router } from '@angular/router';
import { MatSnackBar} from '@angular/material/snack-bar';
import { catchError, tap } from 'rxjs';
import { RoomService } from 'src/app/services/room.service';
import { User } from 'src/app/models/user.interface';
import { UserRole } from 'src/app/models/enum/userRole.enum';
import { LocalStorageService } from 'src/app/services/local-storage.service';
@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.css']
})
export class HomeComponent {

  displayText : boolean = false;
  
  usernameCreate: string = '';
  usernameJoin: string = '';
  joinRoomId: string = '';

  roomVoteType : {
    environnemental : boolean,
    societal : boolean
  } = {
    environnemental : false,
    societal : false
  };

  constructor(private router: Router, private roomService: RoomService, private localStorageService: LocalStorageService, private snackBar: MatSnackBar){}

  public get isUserCreateFilled() {
    return !!this.usernameCreate;
  }
  public get isOneOrMoreRoomTypeChoosed() {
    return this.roomVoteType.environnemental || this.roomVoteType.societal
  }
  public get isUserJoinFilled() {
    return !!this.usernameJoin;
  }

  public get isRoomIdFilled() {
    return !!this.joinRoomId;
  }

  createRoom(){
    if (!this.usernameCreate) {
      return alert('Username is missing !');
    } else {
      const user: User = { username: this.usernameCreate, type: UserRole.gameMaster, envVote: null, socVote: null};
      this.localStorageService.setUser(user);
    }
    this.roomService.createRoom(this.usernameCreate,this.roomVoteType).pipe(
      tap(room => {
        this.localStorageService.setRoom(room);
        this.router.navigate(['room', room.id]);
      })
    ).subscribe();
  }
  collapseText(){
    var textDom = document.getElementById('descriptionText');

    if(!textDom)
    {
      return
    }
    var attributeClass = textDom.getAttribute('class');
    if(!attributeClass)
    {
      return
    }
    this.displayText = !this.displayText;
    if(this.displayText){
      textDom.setAttribute('class',attributeClass.toString().replace('truncate',''));
    }
    else
    {
      textDom.setAttribute('class',attributeClass.toString()+' truncate');
    }
    
    

  }
  joinRoom(){
    if (!this.usernameJoin) {
      return alert('Username is missing !');
    } else {
      const user: User = { username: this.usernameJoin, type: UserRole.player, socVote: null, envVote: null};
      this.localStorageService.setUser(user);
    }
    this.roomService.joinRoom(this.joinRoomId, this.usernameJoin).pipe(
      tap(room => {
        this.localStorageService.setRoom(room);
        this.router.navigate(['room', room.id]);
      }),
      catchError(res => {
        //REVOIR CETTE SNACKBAR
        this.snackBar.open(res.error.message, 'Fermer', {
          duration: 5000
        });
        return [{}]
      })
    ).subscribe();
  }
}
