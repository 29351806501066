import { Component, Inject } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { Router } from '@angular/router';
import { User } from 'src/app/models/user.interface';

@Component({
  selector: 'app-config-popup',
  templateUrl: './config-popup.component.html',
  styleUrls: ['./config-popup.component.css']
})
export class ConfigPopupComponent {
  users: User[] = [];

  constructor(private router: Router,
    public dialogRef: MatDialogRef<ConfigPopupComponent>) {
    }

  closeConfig(): void {
    this.dialogRef.close();
  }

  copyRoomLink(): void {
    this.closeConfig();
    navigator.clipboard.writeText(window.location.href).catch(() => {
      console.error("Unable to copy text");
    });
  }

  returnHome() {
    this.closeConfig();
    this.router.navigate(['']);
  }
}
