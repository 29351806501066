import { Injectable } from '@angular/core';
import { Socket, SocketIoConfig } from 'ngx-socket-io';
import { environment } from 'src/environments/environment';

const config: SocketIoConfig = {
  url: environment.apiUrl,
  options: {
      autoConnect: true
  }
};

@Injectable({
  providedIn: 'root'
})
export class SocketService extends Socket {

  constructor() {
      super(config)
  }
}
